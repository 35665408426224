<template>
  <LineChart 
    :chartdata="chartData"
    :options="chartOptions"
    v-if="chartLoaded"
    :width="null"
    :height="null"
    > 
  </LineChart>            
</template>

<script>
import Widget from '@/components/Widget/Widget';
import axios from '../../../node_modules/axios'
import API from '../../config/api'
import LineChart from '../../components/charts/Line'

export default {
  name: 'ChartTestCountOvertime',
  components: { Widget,LineChart },
  data() {
    return {
      chartLoaded: false,
      chartData: null,
      chartOptions : {
        aspectRatio: 1,
        layout: {
            padding: {
                left: 5,
                right: 5,
                top: 20,
                bottom: 10
            }
        },
        responsive: true,
        legend: {
            display: false,
            position: 'left',
        },     
      },
      errors: []
    }
  },
  methods: {
    loadData() {
      this.chartLoaded = false
      this.chartData = null
      axios.get(API.baseURI+'stats/A1/countovertime')
        .then(
          response => { 
            let data = response.data.value
            this.chartData = this.constructChartData(data)
            this.chartLoaded = true
          }
        )
        .catch(error => {
          //console.log(error)
          this.errors.push(error)
          
        })      
      
    },
    constructChartData(data) {
      //console.log(data)
      let chartData = {
            labels: [],
            datasets: [{
              borderWidth: 3,
              borderColor: "rgba(75,192,192,1)",
              borderCapStyle: 'butt',
              pointRadius: 3,
              pointHitRadius: 5,
              pointBackgroundColor: "#fff",              
              fill: true
            }]
      }
      let chartval = []
      let chartlabel = []
      data = data.slice(data.length-12,data.length)
      for (let i=0;i<data.length;i++) {
        let d = data[i]
        chartval[i] = d['jumlah']
        chartlabel[i] = d['bulan']+'-'+d['tahun'] 
      }
      //console.log('vharval:'+chartval)
      chartData.datasets[0].data = chartval
      chartData.labels = chartlabel
      //console.log(chartData)
      return chartData
    }
  },
  mounted () {
    this.loadData()    
  },
  created () {
    this.$eventHub.$on('refresh', this.loadData);
  },
  beforeDestroy() {
    this.$eventHub.$off('refresh');
  },
};

</script>
