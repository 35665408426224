<template>
  <router-view />
</template>

<script>
export default {
  name: 'App',
  metaInfo: {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: 'Statistik ToSM - Matematika Detik',
      // all titles will be injected into this template
      titleTemplate: '%s | Statistik ToSM - Matematika Detik',
      meta: [
       {property: 'og:image', content: 'logohi.png'}, 
      ]      
    },
  created() {
    const currentPath = this.$router.history.current.path;
    if (currentPath === '/' || currentPath === '/app') {
      this.$router.push('/app/overview');
    }
  },
};
</script>

<style src="./styles/theme.scss" lang="scss" />
