<template>
  <div>
    <b-img fluid src="/skalaA1.png"/>
  </div>
</template>
<script>
export default {
  name : 'SkalaA1',
  data() {
    return {
      rentang: [10,20,30,40,50,60,70],
      warna: ['opmhitam','opmmerah','opmkuning','opmhijau','opmbiru','opmungu','opmputih1','opmputih2'],
      labelGagap: ['Gagap Hitung','Gagap Hitung','Gagap Hitung','Tuntas','Tuntas','Tuntas','Istimewa \u2605','Istimewa \u2605 \u2605'],
      labelOPM: ['opm < 10','10 \u2264 opm < 20', '20 \u2264 opm < 30', 
        '30 \u2264 opm < 40','40 \u2264 opm < 50','50 \u2264 opm < 60'
        ,'60 \u2264 opm < 70','70+']
    }
  },
  methods: {

  }
}
</script>

<style scoped>
  .opmhitam {
    background: #000;
    color: white;    
  }
  .opmmerah {
    background: #F60000;
    color: white;
  }
  .opmkuning {
    background: #FFEE00;
    color: #444;
  }
  .opmhijau {
    background: #4DE94C;
    color: white;
  }
  .opmbiru {
    background: #3783FF;
    color: white;
    margin: auto;
  }
  .opmungu {
    background: #4815AA;
    color: white;
  }
  .opmputih1 {
    background: #fff;
    color: #000;
  }
  .opmputih2 {
    background: #fff;
    color: #000;
  }
</style>