<template>
  <div class="charts-overview">
    <h1 class="page-title">ToSM - <span class="fw-semi-bold">Kompetisi {{query}}</span></h1>
        
      <b-row>        
        <b-col lg=6 sm=12>            
          <b-spinner variant="warning" 
            type="grow" label="Spinning" v-if="loadingFreq">
          </b-spinner>                                              
          <Widget title="Peringkat Berdasarkan Jumlah Tes" v-if="loadedFreq">
            <b-table striped responsive 
              :items="dataFreq" 
              :fields="fieldsFreq" >
              <template slot="index" slot-scope="data">
                {{ data.index + 1 }}
              </template>
            </b-table>                      
          </Widget>
        </b-col>

        <b-col lg=6 sm=12>            
          <b-spinner variant="warning" 
            type="grow" label="Spinning" v-if="loadingOPM">
          </b-spinner>                                              
          <Widget title="Peringkat Berdasarkan Skor ToSM" v-if="loadedOPM">
            <b-table striped responsive 
              :items="dataOPM" 
              :fields="fieldsOPM" >
              <template slot="index" slot-scope="data">
                {{ data.index + 1 }}
              </template>
              <template slot="total" slot-scope="data">
                {{ data.item.opmtotal.toFixed(2)}}
              </template>
            </b-table>                      
          </Widget>
        </b-col>        
      </b-row>
          
  </div>
</template>

<script>
import Widget from '@/components/Widget/Widget';
import axios from '../../../node_modules/axios'
import API from '../../config/api'

export default {
  name: 'KompetisiKlasemen',
  components: { Widget},
  data() {
    return {
      dataFreq: [],
      fieldsFreq : [
        {key:'index', label:'#'},
        {key:'alias', label:'nama'},
        {key:'total_test', label:'Σ Tes'},
        ],
      dataOPM: [],
      fieldsOPM: [
        {key:'index', label:'#'},
        {key:'alias', label:'nama'},
        {key:'opmtambah', label:'tambah'},
        {key:'opmkurang', label:'kurang'},
        {key:'opmkali', label:'kali'},
        {key:'opmbagi', label:'bagi'},
        {key:'total', label:'total'},        
        ],
      loadingFreq : true,
      loadedFreq: false,
      loadingOPM : true,
      loadedOPM: false,
      query: null,
      errors: [],
    }
  },
  methods: {
    formatNumber(num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    },
    loadData(){
      this.loadTestFrequency()
      this.loadTestOPM()
    },    
    loadTestFrequency() {
      this.loading = true
      axios.get(API.baseURI+'/kompetisi/report/maxtest/'+this.query)
        .then(
          response => {            
            this.dataFreq = response.data.value
            this.loadedFreq = true
            this.loadingFreq = false
          }
        )
        .catch(error => {
          this.errors.push(error)
          this.totalUser = 'Gagal mengambil data'
        })
    },
    loadTestOPM() {
      this.loading = true
      axios.get(API.baseURI+'/kompetisi/report/opmtotal/'+this.query)
        .then(
          response => {            
            this.dataOPM = response.data.value
            this.loadedOPM = true
            this.loadingOPM = false
          }
        )
        .catch(error => {
          this.errors.push(error)
          this.totalUser = 'Gagal mengambil data'
        })
    },
    
  },
  mounted () {
    
    this.$eventHub.$on('refresh', this.loadData);
    this.query = this.$route.query.query
    // eslint-disable-next-line no-console
    //console.log('mounted query: '+this.query,this.$route.query)
    if (this.query != '') {
      this.loadData()
    }
  },
  created () {
    //this.$eventHub.$on('refresh', this.loadData);
  },
};

</script>

<style src="./Overview.scss" lang="scss" scoped />
