<template>
  <div class="ketuntasan">
    <b-container>         
    <b-row  align-v="center" v-bind:key="index" v-for="(ops,index) in dataOps">
      <div v-if="!validOps[index]" class="ops container text-center">
        <b-spinner variant="warning" type="grow" label="Spinning"></b-spinner>
      </div>
      <div v-if="dataOps[index]" class="ops container">
        <b-row>
          <b-col md=4 xs=2 m-0>
            <div :class="kelasKetuntasan(dataOps[index].opm,'text-center p-2 mb-2 align-middle ')" >
              {{operasi[index]}}
              <h4 class="fw-semi-bold mb-0"  >{{dataOps[index].opm.toFixed(2)}}</h4>
              OPM
            </div>
          </b-col>
          <b-col md=8 xs=10>
            <h6 class="fw-semi-bold"  >{{ketuntasan(dataOps[index].opm)}}</h6>
            <b-progress height={} class="progress-xs mt-2" :precision="1" variant="warning" :value="100-100*dataOps[index].rank/dataOps[index].total"></b-progress>
            Peringkat <strong>ke-{{dataOps[index].rank}}</strong> dari <strong>{{dataOps[index].total}}</strong><br/>
            Lebih baik dari <strong>{{(100-100*dataOps[index].rank/dataOps[index].total).toFixed(2)}}%</strong> pengguna            
          </b-col>
        </b-row>
      </div>
    </b-row>
    </b-container>
  </div>
</template>

<script>
import Widget from '@/components/Widget/Widget';
import axios from '../../../node_modules/axios'
import API from '../../config/api'

export default {
  name: 'KetuntasanA1User',
  components: { Widget},
  props: {
    uid: null,
  },
  watch: {
    uid: function() {
      this.loadData()
    }
  },
  data() {
    return {      
      validUID: false,
      validOps: [false,false,false,false],
      dataOps : [false,false,false,false],
      dataOpsLoaded : [false,false,false,false],
      operasi: ['Tambah (+)','Kurang (-)','Kali (x)','Bagi (\u00f7)'],      
      user: null,
      userLoading: false,
      userLoaded: false,      
      errors: [],
    }
  },
  methods: {    
    ketuntasan(opm) {
      if (opm>=70) {
        return "ISTIMEWA \u2605 \u2605"
      }
      else if (opm>=60) {
        return "ISTIMEWA \u2605"
      }
      else if (opm>=30) {
        return "TUNTAS"
      }
      return "GAGAP HITUNG"
    },
    kelasKetuntasan(opm, cl) {
      // let cl = 'text-center p-2 mb-2 align-middle '
      if (opm < 10 ) {
        cl += 'opmhitam'
      } 
      else if (opm < 20 ) {
        cl += 'opmmerah'
      } 
      else if (opm < 30 ) {
        cl += 'opmkuning'
      }
      else if (opm < 40 ) {
        cl += 'opmhijau'
      }
      else if (opm < 50 ) {
        cl += 'opmbiru'
      }
      else if (opm < 60 ) {
        cl += 'opmungu'
      }
      else {
        cl += 'opmputih'
      }
      return cl
    },    
    loadData() {
      // console.log('load ops data')      
      if (this.uid ==='' || this.uid ==='0' || this.uid == 0 || this.uid == null) {
        return
      } else {
        //this.loadProfile()
        this.loadKetuntasanA1()
      }
    },
    loadKetuntasanA1() {
      let ops = ['tambah','kurang','kali','bagi']
      for (let i=0; i<ops.length; i++) {
        let o = ops[i]
        axios.get(API.baseURI+'test/urank/A1/'+o+'/'+this.uid)
        .then(
          response => {
            this.validOps.splice(i,1,true)
            if ('rank' in response.data) {
              // console.log(i,'loaded')
              this.dataOps.splice(i,1,response.data)               
              // console.log('dataops',this.dataOps)
            } 
            //console.log('TAMBAH',response.data)
          }
        )
        .catch(error => {
          this.errors.push(error)
          // console.log(error)
          this.totalUser = 'Gagal mengambil data'
        })
      }       
    },
  },
  mounted () {    
    this.loadData()
  },
  created () {    
  },
};

</script>
<style src="./Ketuntasan.scss" lang="scss" scoped />