import Vue from 'vue';
import Vuex from 'vuex';

import layout from './layout';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    layout,
  },
  state: {
    user: '',
    token: ''
  },
  mutations: {
    setUser(state, user) {
      //console.log('set user')
      state.user = user      
    },
    setToken(state, token) {
      //console.log('set user')      
      state.token = token
    },
    logout(state) {
      state.user = ''
    },
  },
  getters: {
    user: state => state.user,
    token: state => state.token
  },
});

