<template>
  <div>
    <div class="embed-responsive embed-responsive-16by9">
      <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/SoFdamzNBdg?rel=0" allowfullscreen></iframe>
    </div>

    <div class="embed-responsive embed-responsive-16by9">
      <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/ShaMdXqxwVQ?rel=0" allowfullscreen></iframe>
    </div>

  </div>  
</template>
<script>
import { YouTubePlayer as Youtube } from 'vue-youtube-embed'

export default {
  name : 'YTChannel',
  components : {Youtube},
  data() {
    return {
      videoId:  'SoFdamzNBdg'
    }
  },
  methods: {
    method (url) {
      this.videoId = this.$youtube.getIdFromURL(url)
      //this.startTime = this.$youtube.getTimeFromURL(url)
    }
  },
  mounted() {
    //this.videoId = this.$youtube.getIdFromURL('https://www.youtube.com/watch?v=SoFdamzNBdg')
    //console.log(this.videoId)
  }

}
</script>