<template>
  <div>    
    <div>            
      <b-spinner variant="primary" label="Spinning" v-if="loadedcnt<4"></b-spinner>
      <div v-if="loadedcnt==4">
        <b-row >
          <b-col md="3" class="mb-2" v-for="(oper,index) in opers" :key="oper">
            <div v-if="oper in ketuntasan">
            <donut :sections="ketuntasan[oper].sections" :size="145">
              <h4>{{opersign[index]}}<br>{{Math.round(ketuntasan[oper].pctgagap)}}%</h4>                            
              GAGAP 
            </donut>
            </div>
          </b-col>
        </b-row>         
      </div>      
    </div>    
  </div>
</template>

<script>
import Donut from 'vue-css-donut-chart/src/components/Donut';
import 'vue-css-donut-chart/dist/vcdonut.css';
import Widget from '@/components/Widget/Widget';
import axios from 'axios'
import API from '../../config/api'


export default {
  name: 'KetuntasanA1Program',
  components: { Widget,Donut},
  props: ['token'],  
  data() {
    return {
      loading: true,
      loaded: false,
      loadedcnt: 0,
      ketuntasan: {},
      opers : ['TAMBAH','KURANG','KALI','BAGI'],
      opersign : ['+','-','x','÷'],           
      errors: []
    }
  },
  computed : {
    
  },
  methods: {
    loadData() {
      this.loadedcnt = 0            
      for (let i=0;i<this.opers.length;i++) {
        this.loadByOperation(this.opers[i])
      }
    },    
    loadByOperation(operation) {      
      axios.get(API.baseURI+'program/ketuntasan/'+this.token+'/A1/'+operation)
        .then(
          response => { 
            let data = response.data.value
            if (data.length>0) {
              let tuntas = 0
              let tidak_tuntas = 0
              for (let i=0;i<data.length;i++) {
                // eslint-disable-next-line no-console
                // console.log(data[i])
                if (data[i].ketuntasan=="TUNTAS") {
                  tuntas = data[i].jumlah
                }
                if (data[i].ketuntasan=="TIDAK TUNTAS") {
                  tidak_tuntas = data[i].jumlah
                }  
              }
              
              // eslint-disable-next-line no-console
              //console.log('data: ',data,tuntas,tidak_tuntas,data.length)              
              let total = tuntas + tidak_tuntas
              let tuntasPct = (tuntas / total) * 100
              let tdkTuntasPct = (tidak_tuntas / total) * 100
              let section = []
              let tidakTuntasObj = {label:'Gagap',value:tdkTuntasPct,color:'red'}
              let tuntasObj = {label:'Tuntas',value:tuntasPct,color:'green'}            
              section.push(tuntasObj)
              section.push(tidakTuntasObj)            
              this.ketuntasan[operation] = {sections:section,pctgagap:tdkTuntasPct}
            }
            
            this.loadedcnt = this.loadedcnt + 1
            //console.log(data)
          }
        )
        .catch(error => {
          // eslint-disable-next-line no-console
          console.log(error)
          this.errors.push(error)          
        })            
    },
  },
  mounted () {
    this.loadData()    
  },
  created () {
    this.$eventHub.$on('refresh', this.loadData);
  },
  beforeDestroy() {
    this.$eventHub.$off('refresh');
  },
};

</script>
<style scoped>
  .progress {
    background-color: #ff0000aa;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
</style>