<template>
  <div class="charts-overview">
    <h1 class="page-title">ToSM - <span class="fw-semi-bold">Top Skor Level A1</span></h1>
    
      
      <b-row>        
        <b-col md=12 sm=12 v-for="item in operations" :key="item">            
          <Widget :title="'OPERASI '+item">
            <b-spinner variant="warning" type="grow" label="Spinning" v-if="!dataLoaded[item]"></b-spinner>                        
            <b-table striped responsive :items="topSkor[item]" :fields="topSkorfields" v-if="dataLoaded[item]">
              <template slot="index" slot-scope="data">{{ data.index + 1 }}</template>
            </b-table>                      
          </Widget>
        </b-col>        
      </b-row>
      
    
  </div>
</template>

<script>
import Widget from '@/components/Widget/Widget';
import axios from '../../../node_modules/axios'
import API from '../../config/api'

export default {
  name: 'RankPage',
  components: { Widget},
  data() {
    return {
      operations: ['TAMBAH','KURANG','KALI','BAGI'],
      dataLoaded: {},
      topSkor: {},      
      topSkorfields: [
          {
            key: 'index', label: '#'
          },
          {
            key: 'opm',
            formatter: value => {
              return value.toFixed(2)
            }
          },
          {
            key: 'alias', label: 'User',
            formatter: value => {
              if (value == null) return 'Anonim (N/A)'
              return value
            }
          },
          {
            key: 'prov', label: 'Provinsi'
          },
          {
            key: 'kab', label: 'Kabupaten'
          },
          {
            key: 'org', label: 'Institusi'
          },
        ],
      errors: [],
    }
  },
  methods: {
    formatNumber(num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    },
    loadData() {
      this.dataLoaded = {}
      this.loadTopSkorA1()
      
    },
    loadTopSkorA1() {
      for (let i=0;i<this.operations.length;i++) {
        axios.get(API.baseURI+'/test/top/A1/'+this.operations[i]+'/25')
        .then(
          response => {            
            this.topSkor[this.operations[i]] = response.data.value            
            this.$set(this.dataLoaded,this.operations[i],true)
          }
        )
        .catch(error => {
          this.errors.push(error)
          this.totalUser = 'Gagal mengambil data'
        })
      }
                 
    },
  },
  mounted () {
    this.$eventHub.$on('refresh', this.loadData);
    this.loadData()
  },
  created () {
    //this.$eventHub.$on('refresh', this.loadData);
  },
};

</script>

<style src="./Overview.scss" lang="scss" scoped />
