<template>
  <PieChart 
    :chartdata="operCountData"
    :options="chartOptions"
    v-if="operCountLoaded"
    > 
  </PieChart>            
</template>

<script>
import Widget from '@/components/Widget/Widget';
import axios from '../../../node_modules/axios'
import API from '../../config/api'
import PieChart from '../../components/charts/Pie'

export default {
  name: 'ChartTestCountByUser',
  components: { Widget,PieChart },
  data() {
    return {
      operCountLoaded: false,
      operCountData: null,
      chartOptions : {
        layout: {
            padding: {
                left: 10,
                right: 10,
                top: 20,
                bottom: 10
            }
        },
        responsive: true,
        legend: {
            display: true,
            position: 'left',
        },     
      },
      errors: []
    }
  },
  methods: {
    loadData() {
      this.operCountLoaded = false
      this.operCountData = null
      axios.get(API.baseURI+'/stats/A1/countbytesttaken')
        .then(
          response => { 
            let data = response.data.value
            this.operCountData = this.constructChartData(data)
            this.operCountLoaded = true
          }
        )
        .catch(error => {
          //console.log(error)
          this.errors.push(error)
          
        })      
      
    },
    constructChartData(data) {
      //console.log(data)
      let chartData = {
            labels: [],
            datasets: [{
              borderWidth: 0.3,
              backgroundColor: ["#0074D9", "#FF4136", "#2ECC40", "#FF851B", "#7FDBFF", "#B10DC9"],
            }]
      }
      let chartval = []
      let chartlabel = []
      for (let i=0;i<data.length;i++) {
        let d = data[i]
        chartval[i] = d['jumlah']
        chartlabel[i] = d['kelas'] 
      }
      //console.log('vharval:'+chartval)
      chartData.datasets[0].data = chartval
      chartData.labels = chartlabel
      //console.log(chartData)
      return chartData
    }
  },
  mounted () {
    this.loadData()
  },
  created () {
    this.$eventHub.$on('refresh', this.loadData);
  },
  beforeDestroy() {
    this.$eventHub.$off('refresh');
  },
};

</script>
