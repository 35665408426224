<template>
  <div>          
      <b-row>
        <b-col lg="12"  sm="12" v-if="dataLoaded">
          <b-form inline class=mt-3>
            Saring data yang tampil
            <b-input-group size="sm" class="ml-2">
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="kata kunci penyaringan"
              ></b-form-input>
              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''" class="mr-2">Clear</b-button>
              </b-input-group-append>              
            </b-input-group>
          </b-form>
          <b-table striped responsive 
            :items="rawData" 
            :fields="fields" :filter="filter"
            :current-page="currentPage"
            :per-page="perPage"
            >
            <template #cell(index)="data">
              {{ data.index + 1 + ((currentPage-1)*perPage)}}
            </template>              
          </b-table>
          <br>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            size="sm"
            class="my-0"
          >
            <template #first-text><span class="text-primary">Awal</span></template>
            <template #prev-text><span class="text-primary">Sebelumnya</span></template>
            <template #next-text><span class="text-primary">Berikutnya</span></template>
            <template #last-text><span class="text-primary">Akhir</span></template>              
            <template #page="{ page, active }">
              <b v-if="active">{{ page }}</b>
              <i v-else>{{ page }}</i>
            </template>
          </b-pagination>          
        </b-col>
      </b-row>      
  </div>
</template>

<script>
import Widget from '@/components/Widget/Widget';
import axios from '../../../node_modules/axios'
import API from '../../config/api'
import moment from '../../../node_modules/moment'

export default {
  name: 'TableHistorisUser',
  components: { Widget},
  
  props: {
    uid: null,
  },
  watch: {
    uid: function() {
      this.loadData()
    }
  },
  data() {
    return {      
      rawData: [],
      dataLoaded: false,
      filter: '',                  
      currentPage: 1,
      perPage: 50,
      totalRows: 0,      
      fields: [                              
          {
            key: 'index',
            label: 'No.',            
          },
          {
            key: 'level',            
          },
          {
            key: 'operation',
            label: 'operasi',                        
          },
          {
            key: 'qnum',
            label:'Σ Soal'                        
          },
          {
            key: 'qcorrect',
            label:'Σ Benar'                        
          },
          {
            key: 'opm',
            formatter: value => {
              if (value>0)
                return value.toFixed(2)
              return '-'
            }            
          },
          {
            key: 'waktu_tes',
            formatter: value => {
              let dt = moment(value)
              return dt.toDate().toLocaleString()
            }                                  
          },
                  
        ],
      errors: [],
    }
  },
  methods: {
    formatNumber(num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    },
    loadData() {
      // eslint-disable-next-line no-console
      //console.log('load data')
      this.rawData = []
      this.loadTestHistory()
    },    
    
    loadTestHistory() {
      if (this.uid ==='' || this.uid ==='0' || this.uid == 0 || this.uid == null) {
        return
      } 
      axios.get(API.baseURI+'user/testhistory/'+this.uid)
        .then(
          response => {            
            this.rawData = response.data.value
            this.dataLoaded = true
            this.totalRows = this.rawData.length
            // eslint-disable-next-line no-console
            // console.log('loaded data',this.rawData.length)            
          }
        )
        .catch(error => {
          this.errors.push(error)
          this.totalTest = 'Gagal mengambil data'
        })          
    },

    
  },
  mounted () {
    this.loadData()
    this.$eventHub.$on('refresh', this.loadData);  
  },
  created () {
    //this.$eventHub.$on('refresh', this.loadData);
  },
};

</script>

<style scoped>
  .chart {
    height: 400px;
  }
</style>