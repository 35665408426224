<template>
  <div>          
      <b-row>
        <b-col lg="12"  sm="12" v-if="dataLoaded">          
          <v-chart class="chart" :option="chartOptions"/>          
        </b-col>
      </b-row>      
  </div>
</template>

<script>
import Widget from '@/components/Widget/Widget';
import axios from '../../../node_modules/axios'
import API from '../../config/api'
// eslint-disable-next-line
//import * as echarts from 'echarts';
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { LineChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  ToolboxComponent,
  MarkLineComponent,

} from "echarts/components";

import VChart, { THEME_KEY } from "vue-echarts";

use([
  CanvasRenderer,
  LineChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  ToolboxComponent,
  MarkLineComponent,

]);


export default {
  name: 'ChartHistorisUser',
  components: { Widget,VChart },
  provide: {
    [THEME_KEY]: "light"
  },
  props: {
    uid: null,
  },
  watch: {
    uid: function() {
      this.loadData()
    }
  },
  data() {
    return {
      chartOptions : {          
          tooltip: {
              trigger: 'axis'
          },
          legend: {
              data: ['TAMBAH', 'KURANG', 'KALI', 'BAGI']
          },
          grid: {
              top: '15%',
              left: '7%',
              right: '7%',
              bottom: '9%',
              containLabel: true
          },
          toolbox: {
              feature: {
                  saveAsImage: {}
              }
          },
          xAxis: {
              type: 'category',
              name: 'Tes ke-',
              nameGap: 31,
              nameLocation: 'middle',
                nameTextStyle: {
                    fontSize: 14,
                    fontStyle: 'bold'
                },
              //axisLabel: {formatter: 'Tes ke-{value}'},        
              data: []
          },
          yAxis: {
              type: 'value',
              name: 'Operation per Minute (OPM)',
              nameGap: 35,
              nameLocation: 'middle',
                nameTextStyle: {
                    fontSize: 14,                    
                    fontStyle: 'bold'
                },
          },
          series: []
        },
      rawData: [],
      dataLoaded: false,
      series : [],            
      errors: [],
    }
  },
  methods: {
    formatNumber(num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    },
    loadData() {
      //console.log('load data')
      this.rawData = []
      this.loadTestHistory()
    },    
    loadTestHistory() {
      if (this.uid ==='' || this.uid ==='0' || this.uid == 0 || this.uid == null) {
        return
      } 
      axios.get(API.baseURI+'user/testhistory/'+this.uid)
        .then(
          response => {
            this.rawData = response.data.value
            if (this.rawData.length > 0) {
              this.prepareSeries()
            }            
          }
        )
        .catch(error => {
          this.errors.push(error)
          this.totalTest = 'Gagal mengambil data'
        })          
    },

    prepareSeries() {
      // eslint-disable-next-line no-console
      // console.log('prepare series: ',this.rawData.length)
      let maxTest = 0;
      for (let i=0;i<this.rawData.length;i++) {
        let data = this.rawData[i];
        // eslint-disable-next-line no-console
        // console.log(i,data)              
        let seriesExists = false;
        for (let s=0;s<this.chartOptions.series.length;s++) {
          let key = this.chartOptions.series[s].name
          // eslint-disable-next-line no-console
          // console.log('ujang')
          if (key == data.operation) {
            this.chartOptions.series[s].data.push(data.opm)
            let seriesDataCnt = this.chartOptions.series[s].data.length
            if (maxTest<seriesDataCnt ) {
              maxTest = seriesDataCnt;
            }
            seriesExists = true                
          }          
        }
        if (!seriesExists) {
          this.chartOptions.series.push({
            name : data.operation,
            type : 'line',
            stack: data.operation,
            data : [data.opm]
        })
        }        
      }
      // eslint-disable-next-line no-console
      // console.log('series: ',this.chartOptions.series)
      for (let tCnt=0;tCnt<maxTest;tCnt++) {
        this.chartOptions.xAxis.data.push(tCnt)
      } 
      // draw marker line @ opm 30
      this.chartOptions.series[0].markLine =  { 
             data:[
              {
                  yAxis : 30,
                  symbol: "none",
                  lineStyle: {                    
                    color: "#11AA11"                    
                  },
                  label: {
                    show: true,                      
                    position: 'middle',
                    formatter: 'Ambang Batas Tuntas'                    
                  }
              }
             ]
            
         }
      this.dataLoaded = true;
    }
  },
  mounted () {
    this.loadData()
    this.$eventHub.$on('refresh', this.loadData);  
  },
  created () {
    //this.$eventHub.$on('refresh', this.loadData);
  },
};

</script>

<style scoped>
  .chart {
    height: 400px;
  }
</style>