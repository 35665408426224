<template>
  <div>    
    <div>
      <p/>
      Sebaran asal pengguna
      <b-spinner variant="primary" label="Spinning" v-if="provLoading"></b-spinner>
      <div v-if="provLoaded">
        <strong>{{provData.length}} dari 34 provinsi ({{provPct.toLocaleString()}}%)</strong>
        <b-progress height={} class="progress-xs mt-2" :precision="1" variant="info" :value="provPct"></b-progress>
      </div>
    </div>
    <div>      
      <b-spinner variant="primary" label="Spinning" v-if="kabLoading"></b-spinner>
      <div v-if="kabLoaded">
        <strong>{{kabData.length}} dari 514 kabupaten/kota ({{kabPct.toLocaleString()}}%)</strong>
        <b-progress height={} class="progress-xs mt-2" :precision="1" variant="danger" :value="kabPct"></b-progress>
      </div>
    </div>
  </div>
</template>

<script>
import Widget from '@/components/Widget/Widget';
import axios from '../../../node_modules/axios'
import API from '../../config/api'


export default {
  name: 'GeoCoverage',
  components: { Widget },
  data() {
    return {
      provLoading: false,
      kabLoading: false,
      provLoaded: false,
      kabLoaded: false,
      provData: null,
      kabData: null,
      provPct: 0,
      kabPct: 0,      
      errors: []
    }
  },
  computed : {
    
  },
  methods: {
    loadData() {
      this.loadDataProv()
      this.loadDataKab()
    },
    loadDataProv() {
      this.provLoading = true
      axios.get(API.baseURI+'/user/origin/prov')
        .then(
          response => { 
            // console.log('prov loaded')
            this.provData = response.data.value            
            this.provLoading = false
            this.provLoaded = true
            this.provPct = Math.round(100 * this.provData.length / 34)
          }
        )
        .catch(error => {
          //console.log(error)
          this.errors.push(error)
          this.provLoading = false
        })      
      
    },
    loadDataKab() {
      this.kabLoading = true
      axios.get(API.baseURI+'/user/origin/kab')
        .then(
          response => { 
            this.kabData = response.data.value
            this.kabPct = Math.round(100 * this.kabData.length / 514)
            this.kabLoading = false
            this.kabLoaded = true
          }
        )
        .catch(error => {
          //console.log(error)
          this.errors.push(error)
          this.kabLoading = false
        })            
    },
  },
  mounted () {
    this.loadData()    
  },
  created () {
    this.$eventHub.$on('refresh', this.loadData);
  },
  beforeDestroy() {
    this.$eventHub.$off('refresh');
  },
};

</script>
