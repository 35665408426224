<template>
  <div>
    <h1 class="page-title">ToSM - <span class="fw-semi-bold">Kompetisi Baru</span></h1>
    <div>
    <b-card class="mt-3" header="Lengkapi isian berikut">
    <b-form @submit="onSubmit" @reset="onReset" >
      <b-form-group
        id="input-group-0"
        label="Judul"
        label-for="input-0"
        description="Masukkan judul kompetisi"
      >
        <b-form-input
          id="input-0"
          v-model="form.judul"
          placeholder="Judul kompetisi"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group
        id="input-group-1"
        label="Penyelenggara"
        label-for="input-1"
        description="Masukkan nama penyelenggara, misal: SMA Negeri 1 Atas Awan, POSI, PPMD"
      >
        <b-form-input
          id="input-1"
          v-model="form.penyelenggara"
          placeholder="Penyelenggara kompetisi"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-2" label="Kode Kompetisi" label-for="input-2"
        description="Kode/token kompetisi untuk kebutuhan registrasi user melalui app"
        >
        <b-form-input
          id="input-2"
          v-model="form.query"
          placeholder="Kode kompetisi"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-3" label="Deskripsi" label-for="input-3"
        description="Deskripsi/keterangan tambahan terkait kompetisi"  
      >
        <b-form-textarea
          id="input-3"
          v-model="form.deskripsi"
          placeholder="Deskripsi kompetisi"
          required
        ></b-form-textarea>
      </b-form-group>

      <b-form-group id="input-group-3" label="Level kompetisi" label-for="input-3"
        description="Level kompetisi (nasional/provinsi/kabupaten kota)"  
      > 
        <b-row>
          <b-col md="3">
            <b-form-select v-model="form.level" :options="lingkup_c" v-on:change="levelChanged"></b-form-select></b-col>
          <b-col md="4" v-if="form.level === 'provinsi' || form.level === 'kabupaten/kota'">
            <b-form-select required v-model="prov" :options="provinsi_l" placeholder="Provinsi" v-on:change="provChanged"></b-form-select>
          </b-col>
          <b-col md="5" v-if="form.level == 'kabupaten/kota' && prov!='' && kabupaten_l.length>0">
            <b-form-select required v-model="kab" :options="kabupaten_l" placeholder="Kabupaten"></b-form-select>
          </b-col>
          <b-col md="5" v-if="form.level == 'institusi'">
            <b-form-input
              id="input-2"
              v-model="form.region"
              placeholder="Nama institusi/sekolah"
              required
            ></b-form-input>
          </b-col>
          
        </b-row>
      </b-form-group>            

      <b-form-group id="input-group-4" label="Kompetisi dimulai pada" label-for="input-3">
        <b-row>
          <b-col md="6"><b-form-input required v-model="start" type="datetime-local"  class="ml-2 mt-2"/></b-col>
        </b-row>
      </b-form-group>

      <b-form-group id="input-group-4" label="Kompetisi berakhir pada" label-for="input-3">
        <b-row>
          <b-col md="6"><b-form-input required v-model="end" type="datetime-local"  class="ml-2 mt-2"/></b-col>
        </b-row>
      </b-form-group>

      

      <b-button type="submit" variant="primary" class="mr-3">Submit</b-button>
      <b-button type="reset" variant="danger">Reset</b-button>
    </b-form>
    </b-card>
    
  </div>
  </div>
</template>

<script>
import Widget from '@/components/Widget/Widget';

export default {
  name: 'KompetisiCreate',
  components: { Widget},
  data() {
    return {
      form :{
        judul   : '',
        penyelenggara: '',
        query : '',
        deskripsi : '',
        level: 'nasional',
        region: '',
        start: '',
        end: '',
      },
      prov: '',
      kab: '',
      start: '',
      end: '',
      lingkup_c : ["nasional","provinsi","kabupaten/kota","institusi"],
      errors: [],
      provinsi_l: [],
      kabupaten_l: [],
    }
  },
  methods: {
    onSubmit(event) {
      event.preventDefault()

      if (this.form.level!='nasional') {
        if (this.form.level === 'provinsi') {
          this.form.region = this.prov.text
        } else if (this.form.level === 'kabupaten/kota') {
          this.form.region = this.kab
        }
      }
      this.form.start = this.start
      this.form.end = this.end
      //this.form.deskripsi = 'Program pemetaan kemampuan hitung dasar intuitif'
      // eslint-disable-next-line no-console
      //console.log('form:',this.form,this.form.level,this.prov,this.kab)
      this.$http.post('kompetisi/create',this.form)
        .then(
          response => {
            // eslint-disable-next-line no-console
            // console.log('resp:',response.data)
            let id = response.data.kompetisi.id                            
            this.$router.push('/admin/kompetisi/'+id)
          }
        )
        .catch(error => {          
          this.errors.push(error)          
        })
    },
    onReset(event) {
      event.preventDefault()      
      this.form.judul = ''
      this.form.penyelenggara = ''
      this.form.query = ''
      this.form.deskripsi = ''
      this.form.level = ''
      this.form.region = ''
      this.form.start = ''
      this.form.end = ''
      this.$nextTick(() => {
        
      })
    },
    setDefaultDate() {
      let date = new Date(), y = date.getFullYear(), m = date.getMonth()
      let startDt = new Date(y, m-1, 1);
      let endDt = new Date(y, m + 1, 0);
      this.start = ''+startDt.getFullYear()+'-'+('0'+(startDt.getMonth()+1)).slice(-2)+'-'+('0'+startDt.getDate()).slice(-2)
      // eslint-disable-next-line no-console
      //console.log('startp',this.startPeriod)
      this.end = ''+endDt.getFullYear()+'-'+('0'+(endDt.getMonth()+1)).slice(-2)+'-'+('0'+endDt.getDate()).slice(-2)
    },
    levelChanged() {
      if (this.form.level == 'nasional' || this.form.level == 'institusi') {
        this.form.region = ''
        this.prov = ''
        this.kab = ''
      }
      if (this.form.level == 'provinsi' || this.form.level == 'kabupaten/kota') {
        this.prov = ''
        this.kab = '' 
      }
    },
    provChanged(prov) {
      if (this.form.level == 'nasional') this.form.region = ''
      if (this.form.level == 'provinsi') {
        this.kab = '' 
      }
      if (this.form.level == 'kabupaten/kota') {
        this.kab = '' 
        // eslint-disable-next-line no-console
        //console.log('refresh kab triggered on prov',prov)
        this.refreshKab(prov)                
      } 
    },
    refreshKab(prov) {
      this.$http.get('region/kabkot/'+prov.id)
        .then(
          response => {              
            let kabupaten = response.data.value
            // eslint-disable-next-line no-console
            //console.log('kabupaten:',kabupaten.length,prov.id)  
            this.kabupaten_l = []
            this.kabupaten_l.push({ value: 0, text: '--Pilih kabupaten', disabled: true })
            for (let idx = 0; idx < kabupaten.length; idx++) {
              let kab = kabupaten[idx]
              // eslint-disable-next-line no-console
              //console.log(kab)
              this.kabupaten_l.push({value:kab.kab,text:kab.kab})
            }
           }
        )
        .catch(error => {          
          this.errors.push(error)          
        })
    },
    loadRegion() {
      this.$http.get('region/prov')
        .then(
          response => {
            // let token = response.data.kompetisi.id
            // eslint-disable-next-line no-console
            // console.log('prov:',response.data)                
            let provinsi = response.data.value
            this.provinsi_l = []
            this.provinsi_l.push({ value: 'null', text: '--Pilih provinsi', disabled: true, selected: true })
            for (let i=0;i<provinsi.length;i++) {
              let prov = provinsi[i]
              this.provinsi_l.push({value:{id:prov.id,text:prov.prov}, text:prov.prov})
            }
          }
        )
        .catch(error => {          
          this.errors.push(error)          
        })
    }    
  },
  mounted () {
    this.$eventHub.$on('refresh', this.loadData);
    this.setDefaultDate()
    this.loadRegion()
    //this.loadData()
  },
  created () {
    //this.$eventHub.$on('refresh', this.loadData);
  },
};

</script>
