<template>
  <div>          
      <b-row>
        <b-col lg="12"  sm="12" >
          
            <b-table striped responsive :items="summaryA1" :fields="summaryA1fields"></b-table>
          
        </b-col>
      </b-row>      
  </div>
</template>

<script>
import Widget from '@/components/Widget/Widget';
import axios from '../../../node_modules/axios'
import API from '../../config/api'
export default {
  name: 'StatsProgram',
  components: { Widget },
  props: ['token'],  
  data() {
    return {
      
      summaryA1: [],
      summaryA1fields: [          
          'Operasi',
          {
            key: 'Jumlah',
            label : 'Σ Peserta'
          },          
          {
            key: 'Rerata',
            formatter: value => {
              return value.toFixed(2)
            }
          },
          {
            key: 'Minimum',
            label: 'Terendah',
            formatter: value => {
              return value.toFixed(2)
            }
          },
          {
            key: 'Maksimum',
            label: 'Tertinggi',
            formatter: value => {
              return value.toFixed(2)
            }
          },  
          {
            key: 'StdDeviasi',
            label: 'Simp. Baku',
            formatter: value => {
              return value.toFixed(2)
            }
          },
        ],
      errors: [],
    }
  },
  methods: {
    formatNumber(num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    },
    loadData() {
      //console.log('load data')
      this.summaryA1 = []
      this.loadSummaryA1()
    },
    
    loadSummaryA1() {
      axios.get(API.baseURI+'program/stats/'+this.token)
        .then(
          response => {
            this.summaryA1 = response.data.value
          }
        )
        .catch(error => {
          this.errors.push(error)
          this.totalTest = 'Gagal mengambil data'
        })          
    },
  },
  mounted () {
    this.loadData()
    this.$eventHub.$on('refresh', this.loadData);  
  },
  created () {
    //this.$eventHub.$on('refresh', this.loadData);
  },
};

</script>

