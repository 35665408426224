<template>
  <nav
    :class="{sidebar: true, sidebarStatic, sidebarOpened}"
    @mouseenter="sidebarMouseEnter"
    @mouseleave="sidebarMouseLeave"
  >
    <header class="logo">
      <router-link to="/app">
        
      </router-link>
    </header>
    <ul class="nav">
      <NavLink
        header="Overview"
        link="/app/overview"
        iconName="flaticon-home"
        index="dashboard"
        isHeader
      />
      <NavLink
        header="Top Skor A1"
        link="/app/rank"
        iconName="flaticon-star"
        index="dashboard"
        isHeader
      />
      <NavLink
        header="Sebaran OPM"
        link="/app/sebaranopm"
        iconName="flaticon-document"
        index="dashboard"
        isHeader
      />
      <NavLink
        v-if="false"
        header="Peringkat Pengguna"
        link="/app/rankuser/0"
        iconName="flaticon-users"
        index="dashboard"
        isHeader
      />
      
      <NavLink
        header="Data Mitra"
        link="/app/program"
        iconName="flaticon-database"
        index="dashboard"
        isHeader
      />
      
      <NavLink
        header="Hapus Data Anda"
        link="/app/permohonan-hapus-data"
        iconName="flaticon-trash"
        index="dashboard"
        isHeader
      />
      
      <NavLink
        header="Kebijakan Privasi"
        link="/app/privacy-policy"
        iconName="flaticon-document"
        index="dashboard"
        isHeader
      />
      <NavLink
        header="Login"
        link="/login"
        iconName="flaticon-login"
        index="dashboard"
        isHeader
        v-if="$store.getters.user===''"
      />

      <NavLink
        v-if="$store.getters.user"
        :activeItem="activeItem"
        header="Admin"
        link="/admin/datamitra"
        iconName="flaticon-lock"
        index="admin"
        :childrenLinks="[
          { header: 'Pencarian user', link: '/admin/usersearch' },
          { header: 'Tambah mitra', link: '/admin/newmitra' },
          { header: 'Daftar mitra', link: '/admin/allmitra' },
          { header: 'Tambah kompetisi', link: '/admin/newkompetisi' },
          { header: 'Daftar kompetisi', link: '/admin/allkompetisi' },
        ]"
      />

      <NavLink
        header="Logout"
        link="/login"        
        iconName="flaticon-exit"
        index="dashboard"
        isHeader
        v-if="$store.getters.user"
        onClick="logout"
      />
      
    </ul>
  </nav>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import isScreen from '@/core/screenHelper';
import NavLink from './NavLink/NavLink';

export default {
  name: 'Sidebar',
  components: { NavLink },
  data() {
    return {
      alerts: [
        {
          id: 0,
          title: 'Sales Report',
          value: 15,
          footer: 'Calculating x-axis bias... 65%',
          color: 'info',
        },
        {
          id: 1,
          title: 'Personal Responsibility',
          value: 20,
          footer: 'Provide required notes',
          color: 'danger',
        },
      ],
    };
  },
  methods: {
    ...mapActions('layout', ['changeSidebarActive', 'switchSidebar']),
    setActiveByRoute() {
      const paths = this.$route.fullPath.split('/');
      paths.pop();
      this.changeSidebarActive(paths.join('/'));
    },
    sidebarMouseEnter() {
      if (!this.sidebarStatic && (isScreen('lg') || isScreen('xl'))) {
        this.switchSidebar(false);
        this.setActiveByRoute();
      }
    },
    sidebarMouseLeave() {
      if (!this.sidebarStatic && (isScreen('lg') || isScreen('xl'))) {
        this.switchSidebar(true);
        this.changeSidebarActive(null);
      }
    },
  },
  created() {
    this.setActiveByRoute();
  },
  computed: {
    ...mapState('layout', {
      sidebarStatic: state => state.sidebarStatic,
      sidebarOpened: state => !state.sidebarClose,
      activeItem: state => state.sidebarActiveElement,
    }),
  },
};
</script>

<!-- Sidebar styles should be scoped -->
<style src="./Sidebar.scss" lang="scss" scoped/>
