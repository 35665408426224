<template>
  <div>
    <h1 class="page-title"><span class="fw-semi-bold">Peringkat sementara peserta kompetisi</span></h1>
    <div>
      <b-row>        
        <b-col md="8" v-if="infoLoaded && standingLoaded">
          <Widget>
            <h5>Nama peserta: {{ standing.alias }}</h5>
            <h6>institusi: {{standing.org}}</h6>
            <h6>Pada Kompetisi {{info.judul}} yang diselenggarakan oleh {{info.penyelenggara}}</h6>            
            <span class="small text-danger">periode: {{formatDate(info.start)}} WIB sampai {{formatDate(info.end)}} WIB </span>
            <p></p>

          </Widget>
        </b-col>
      </b-row>
      <b-row>        
        <b-col md="4" class="my-1">
          <Widget title="Berdasarkan OPM" v-if="standingLoaded">
            <h4 class="text-primary"># {{ standing.rank_opm }}</h4>
            <h6>Total skor: {{ standing.opmtotal.toFixed(2) }}</h6>
            <span class="small" v-if="standing.hasOwnProperty('opmtambah')"> + {{ standing.opmtambah.toFixed(2) }} </span>
            <span class="small" v-if="standing.hasOwnProperty('opmkurang')"> - {{ standing.opmkurang.toFixed(2) }}</span>
            <span class="small" v-if="standing.hasOwnProperty('opmkali')"> × {{ standing.opmkali.toFixed(2) }}</span>
            <span class="small" v-if="standing.hasOwnProperty('opmbagi')"> ÷ {{ standing.opmbagi.toFixed(2) }}</span>
          </Widget>
        </b-col>
        <b-col md="4" class="my-1">
          <Widget title="Berdasarkan Σ Tes" v-if="standingLoaded">
            <h4 class="text-primary"># {{ standing.rank_tcount }}</h4>
            <h6>Jumlah tes: {{ standing.tcount }}</h6>
          </Widget>
        </b-col>     
      </b-row>  
      <b-row>
        <b-col md="8" class="my-1">
          <Widget title="Riwayat tes pada periode kompetisi" v-if="infoLoaded">
            <TestHistoryUserKompetisi :uid="uid" :uuid="uuid"/>
          </Widget>
        </b-col>   
      </b-row>    
    </div>    
  </div>
</template>

<script>
import Widget from '@/components/Widget/Widget';
import TestHistoryUserKompetisi from '../tosmcomponents/TestHistoryUserKompetisi.vue';


export default {
  name: 'RankKompetisiUser',
  components: { Widget, TestHistoryUserKompetisi },
  data() {
    return {
      uid: null,
      uuid: null,
      filter:'',
      k_id: '',
      resultsLoaded: false,
      infoLoaded: false,
      info: null,
      standingLoaded: false,
      standing: null,            
      errors: [],
    }
  },
  methods: {
    formatNumber(num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    },
    loadData() {
      this.filter = ''
      //this.infoLoaded = false
      //this.resultsLoaded = false      
      this.loadInfo()
      this.loadStanding()
      //this.loadResults()     
    },
    formatDate(dt) {
      dt = new Date(dt)
      let year = dt.getFullYear()
      
      let month = (1 + dt.getMonth()).toString();
      month = month.length > 1 ? month : '0' + month;
      
      let day = dt.getDate().toString();
      day = day.length > 1 ? day : '0' + day;

      let hr = dt.getHours()
      hr = hr.length > 1 ? hr : '0' + hr
      let min = dt.getMinutes()
      min = min.length > 1 ? min : '0' + min
      
      return day + '/' + month + '/' + year + " "+ hr +":"+min;
    },
    loadInfo() {
      this.$http.get('kompetisi/detailuuid/'+this.uuid)
        .then(
          response => {            
            this.info = response.data.value            
            this.infoLoaded = true
            // eslint-disable-next-line no-console
            //console.log("info",this.info)
          }
        )
        .catch(error => {
          this.errors.push(error)
          //this.totalUser = 'Gagal mengambil data'
        })
    },
    loadStanding() {
      this.$http.get('kompetisi/standinguuid/'+this.uid+'/'+this.uuid)
        .then(
          response => {            
            this.standing = response.data.value            
            this.standingLoaded = true
            // eslint-disable-next-line no-console
            console.log("standing",this.standing)
          }
        )
        .catch(error => {
          // eslint-disable-next-line no-console
          console.log("standing error:",error)
          this.errors.push(error)
          //this.totalUser = 'Gagal mengambil data'
        })
    }
  },
  mounted () {
    this.$eventHub.$on('refresh', this.loadData);
    // eslint-disable-next-line no-console
    console.log("route params",this.$route.params)
    this.uid = this.$route.params.uid    
    this.uuid = this.$route.params.uuid
    this.loadData()
  },
  created () {
    //this.$eventHub.$on('refresh', this.loadData);
  },
};

</script>
