<template>
  <div>
    <h1 class="page-title">ToSM - <span class="fw-semi-bold">Data mitra</span></h1>
    <div>
      <b-row>
        <b-col md=4 v-if="!$route.params.token">          
            <b-card bg-variant="warning-light">
              <h4>Masukkan token program</h4>
              <b-form inline class="m-1">
                <b-input v-model="token" @keyup.enter="loadData"> </b-input> <br>
                <b-button class="ml-2 small" variant="warning" @click="loadData">Submit</b-button>
              </b-form>              
              
              <span class="small italic mt-2">Mitra terdaftar dapat mengakses data sekolah/institusi masing-masing
                dengan menggunakan token. 
                Jika anda mewakiliki institusi dan tertarik dengan fitur ini, 
                hubungi kami melalui <a href="mailto:mathdetik@gmail.com">email</a> atau 
                <a href="https://wa.me/+6285742109009">whatsapp</a></span>
            </b-card>
          
        </b-col>
        <b-col md=8 v-if="infoLoaded">
          <Widget title="Informasi program">
            <h4>Institusi: {{info.query}}</h4>
            <h5>Deskripsi: {{info.deskripsi}}</h5>
            Kontak: {{info.contact}} <br>
            Token: {{info.token}} <br>
            Mulai: {{info.program_start}} <br>
            Berakhir: {{info.program_end}}            
          </Widget>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="6" class="my-1">
          <Widget title="Statistik" v-if="infoLoaded">
            <stats-program :token="token"/>
          </Widget>
        </b-col>
        
        <b-col lg="6" class="my-1">
          <Widget title="Persentase Gagap Hitung" v-if="infoLoaded">
            <ketuntasan-a1-program :token="token"/>
          </Widget>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="my-1">
          <Widget title="Histogram Skor OPM" v-if="infoLoaded">
            <histogram-program :token="token"/>
          </Widget>
        </b-col>
      </b-row>      
      <b-row>        
        <b-col lg="12" class="my-1">
          <Widget title="Hasil Tes" v-if="infoLoaded">
            <max-opm-program-by-period :token="token"/>
          </Widget>
        </b-col>        
      </b-row>      
    </div>    
  </div>
</template>

<script>
import Widget from '@/components/Widget/Widget';
import axios from '../../../node_modules/axios'
import API from '../../config/api'
import StatsProgram from '../tosmcomponents/StatsProgram.vue';
import KetuntasanA1Program from '../tosmcomponents/KetuntasanA1Program'
import MaxOpmProgramByPeriod from '../tosmcomponents/MaxOpmProgramByPeriod'
import HistogramProgram from '../tosmcomponents/HistogramProgram'

export default {
  name: 'ProgramResultPage',
  components: { Widget,StatsProgram,KetuntasanA1Program,MaxOpmProgramByPeriod,HistogramProgram},
  data() {
    return {
      filter:'',
      token: '',
      resultsLoaded: false,
      infoLoaded: false,
      info: null,      
      results: [],
      resultsFields: [          
          {
            key: 'nama',
            label: 'Nama',
            sortable: true,            
          },          
          {
            key: 'level',            
          },
          {
            key: 'operation',
            label: 'Operasi',            
          },
          {
            key: 'opm',
            label: 'Skor (OPM)',            
          },
          {
            key: 'waktu_tes', 
            label: 'Waktu Tes',           
          },
        ],            
      infoFields: [          
          {
            key: 'token',            
          },
          {
            key: 'query',            
          },
          {
            key: 'program_start',            
          },
          {
            key: 'program_end',            
          },          
        ],
      errors: [],
    }
  },
  methods: {
    formatNumber(num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    },
    loadData() {
      this.filter = ''
      this.infoLoaded = false
      this.resultsLoaded = false      
      this.loadInfo()
      this.loadResults()     
    },
    
    loadResults() {
      axios.get(API.baseURI+'program/tests/'+this.token+'/25')
        .then(
          response => {
            this.results = response.data.value            
            this.resultsLoaded = true
          }
        )
        .catch(error => {
          this.errors.push(error)
          //this.totalUser = 'Gagal mengambil data'
        })
    },
    loadInfo() {
      axios.get(API.baseURI+'program/info/'+this.token)
        .then(
          response => {            
            this.info = response.data.value[0]            
            this.infoLoaded = true
          }
        )
        .catch(error => {
          this.errors.push(error)
          //this.totalUser = 'Gagal mengambil data'
        })
    },    
  },
  mounted () {
    this.$eventHub.$on('refresh', this.loadData);
    if (this.$route.params.token) {
      this.token = this.$route.params.token
      this.loadData()
    }
    //this.loadData()
  },
  created () {
    //this.$eventHub.$on('refresh', this.loadData);
  },
};

</script>

<style src="./Overview.scss" lang="scss" scoped />
