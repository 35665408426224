<template>
  <div class="charts-overview">
    <h1 class="page-title">ToSM - <span class="fw-semi-bold">Sebaran skor OPM level A1</span></h1>
    <div>
      <b-row>
        <b-col lg='4' xl='4' xs='6' >
          <Widget title="Operasi Tambah">
            <BarChart 
              :chartdata="tambahChartData"
              :options="chartOptions"
              v-if="sotambahLoaded"
              > 
            </BarChart>            
          </Widget>
        </b-col>
        <b-col lg='4' xl='4' xs='6' >
          <Widget title="Operasi Kurang">
            <BarChart 
              :chartdata="kurangChartData"
              :options="chartOptions"
              v-if="sokurangLoaded"
              > 
            </BarChart>            
          </Widget>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg='4' xl='4' xs='6' >
          <Widget title="Operasi Kali">
            <BarChart 
              :chartdata="kaliChartData"
              :options="chartOptions"
              v-if="sokaliLoaded"
              > 
            </BarChart>            
          </Widget>
        </b-col>
        <b-col lg='4' xl='4' xs='6' >
          <Widget title="Operasi Bagi">
            <BarChart 
              :chartdata="bagiChartData"
              :options="chartOptions"
              v-if="sobagiLoaded"
              > 
            </BarChart>            
          </Widget>
        </b-col>
      </b-row>
    </div>
    
  </div>
</template>

<script>
import Widget from '@/components/Widget/Widget';
import axios from '../../../node_modules/axios'
import API from '../../config/api'
import BarChart from '../../components/charts/Bar'

export default {
  name: 'SebaranPage',
  components: { Widget,BarChart },
  data() {
    return {
      sotambahLoaded: false,
      sokurangLoaded: false,
      sokaliLoaded: false,
      sobagiLoaded: false,
      tambahChartData: null,
      kurangChartData: null,
      kaliChartData: null,
      bagiChartData: null,
      chartOptions : {
        layout: {
            padding: {
                left: 10,
                right: 10,
                top: 20,
                bottom: 10
            }
        },
        scales: {
          yAxes: [{
            scaleLabel: {
              display: true,
              labelString: 'JUMLAH TES'
            }
          }],
           xAxes: [{
            barPercentage: 1.0,
            categoryPercentage: 1.0,
            gridLines: {
              display: false
            },
            scaleLabel: {
              display: true,
              labelString: 'RENTANG SKOR'
            }
          }]
        },
        legend: {
            display: false
        },     
      },
      errors: []
    }
  },
  methods: {
    formatNumber(num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    },
    loadData() {
      //console.log('soloadata')
      this.sotambahLoaded = false
      this.sokurangLoaded = false
      this.sokaliLoaded = false
      this.sobagiLoaded = false
      axios.get(API.baseURI+'/test/histogram/A1/tambah')
        .then(
          response => { 
            let data = response.data.value
            this.tambahChartData = this.constructChartData(data)
            this.sotambahLoaded = true
          }
        )
        .catch(error => {
          //console.log(error)
          this.errors.push(error)
          
        })      
      axios.get(API.baseURI+'/test/histogram/A1/kurang')
        .then(
          response => { 
            let data = response.data.value
            this.kurangChartData = this.constructChartData(data)
            this.sokurangLoaded = true
          }
        )
        .catch(error => {
          //console.log(error)
          this.errors.push(error)
          
        })
      axios.get(API.baseURI+'/test/histogram/A1/kali')
        .then(
          response => { 
            let data = response.data.value
            this.kaliChartData = this.constructChartData(data)
            this.sokaliLoaded = true
          }
        )
        .catch(error => {
          //console.log(error)
          this.errors.push(error)
          
        })
      axios.get(API.baseURI+'/test/histogram/A1/bagi')
        .then(
          response => { 
            let data = response.data.value
            this.bagiChartData = this.constructChartData(data)
            this.sobagiLoaded = true
          }
        )
        .catch(error => {
          //console.log(error)
          this.errors.push(error)
          
        })
    },
    constructChartData(data) {
      //console.log(data)
      let chartData = {
            labels: ['0-10','10-20','20-30','30-40','40-50','50-60','60-70','70+'],
            datasets: [{
            label: 'Jumlah Tes',
            backgroundColor: [
                'rgba(0, 0, 0, 0.8)',
                'rgba(225, 50, 50, 0.8)',
                'rgba(225, 225, 0, 0.8)',
                'rgba(50, 255, 50, 0.8)',
                'rgba(50, 50, 225, 0.8)',
                'rgba(150, 0, 225, 0.8)',
                'rgba(255, 255, 255, 0.8)',
                'rgba(255, 255, 255, 0.8)'
                
            ],
            borderColor: [
                'rgba(252,201,96, 1)',
                'rgba(252,201,96, 1)',
                'rgba(252,201,96, 1)',
                'rgba(252,201,96, 1)',
                'rgba(252,201,96, 1)',
                'rgba(252,201,96, 1)',
                'rgba(252,201,96, 1)',
                'rgba(252,201,96, 1)',
            ],
            borderWidth: 0.3
        }]
      }
      let chartval = []
      for (let i=0;i<data.length;i++) {
        let d = data[i]
        chartval[i] = d['jumlah'] 
      }
      //console.log('vharval:'+chartval)
      chartData.datasets[0].data = chartval
      //console.log(chartData)
      return chartData
    }
  },
  mounted () {
    this.loadData()    
    this.$eventHub.$on('refresh', this.loadData);
  },
  created () {
    //this.$eventHub.$on('refresh', this.loadData);
  },
};

</script>

<style src="./Overview.scss" lang="scss" scoped />
