<template>
  <div>    
    <div>            
      <b-spinner variant="primary" label="Spinning" v-if="loadedcnt<4"></b-spinner>
      <div v-if="loadedcnt==4">
        <b-row>
          <b-col class="mb-2">
            <donut :sections="ketuntasan.TAMBAH.sections" :size="175">
              <h2>+<br>{{Math.round(ketuntasan.TAMBAH.pctgagap)}}%</h2>                            
              GAGAP 
            </donut>
          </b-col>
          <b-col class="mb-2">
            <donut :sections="ketuntasan.KURANG.sections" :size="175">
              <h2>-<br>{{Math.round(ketuntasan.KURANG.pctgagap)}}%</h2>                            
              GAGAP 
            </donut>
          </b-col>
          <b-col class="mb-2">
            <donut :sections="ketuntasan.KALI.sections" :size="175">
              <h2>x<br>{{Math.round(ketuntasan.KALI.pctgagap)}}%</h2>                            
              GAGAP 
            </donut>
          </b-col>
          <b-col class="mb-2">
            <donut :sections="ketuntasan.BAGI.sections" :size="175">
              <h2>÷<br>{{Math.round(ketuntasan.BAGI.pctgagap)}}%</h2>                            
              GAGAP 
            </donut>
          </b-col>
        </b-row>                
         
      </div>      
    </div>    
  </div>
</template>

<script>
import Donut from 'vue-css-donut-chart/src/components/Donut';
import 'vue-css-donut-chart/dist/vcdonut.css';
import Widget from '@/components/Widget/Widget';
import axios from '../../../node_modules/axios'
import API from '../../config/api'


export default {
  name: 'KetuntasanA1',
  components: { Widget,Donut},
  data() {
    return {
      loading: true,
      loaded: false,
      loadedcnt: 0,
      ketuntasan: {},           
      errors: []
    }
  },
  computed : {
    
  },
  methods: {
    loadData() {
      this.loadedcnt = 0      
      let opers = ['TAMBAH','KURANG','KALI','BAGI']
      for (let i=0;i<opers.length;i++) {
        this.loadByOperation(opers[i])
      }
    },    
    loadByOperation(operation) {      
      axios.get(API.baseURI+'test/ketuntasan/A1/'+operation)
        .then(
          response => { 
            let data = response.data.value
            let tuntas = data[1].jumlah
            let tidak_tuntas = data[0].jumlah
            let total = tuntas + tidak_tuntas
            let tuntasPct = (tuntas / total) * 100
            let tdkTuntasPct = (tidak_tuntas / total) * 100
            let section = []
            let tidakTuntasObj = {label:'Gagap',value:tdkTuntasPct,color:'red'}
            let tuntasObj = {label:'Tuntas',value:tuntasPct,color:'green'}            
            section.push(tuntasObj)
            section.push(tidakTuntasObj)            
            this.ketuntasan[operation] = {sections:section,pctgagap:tdkTuntasPct}
            this.loadedcnt = this.loadedcnt + 1
            //console.log(data)
          }
        )
        .catch(error => {
          //console.log(error)
          this.errors.push(error)          
        })            
    },
  },
  mounted () {
    this.loadData()    
  },
  created () {
    this.$eventHub.$on('refresh', this.loadData);
  },
  beforeDestroy() {
    this.$eventHub.$off('refresh');
  },
};

</script>
<style scoped>
  .progress {
    background-color: #ff0000aa;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
</style>