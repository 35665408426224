<template>
  <div>
    <div v-if="loaded" class="mb-3 mt-1">
      <h4 class="fw-semi-bold">{{user.alias}}</h4>
      <h5>{{user.prov}} - {{user.kab}} </h5>
      <h5>{{user.org}} </h5>
      <h5>{{user.suborg}} </h5>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import API from '../../config/api'
export default {
  name: 'InfoUser',
  props: {
    uid: null,
  },
  watch: {
    uid: function() {
      this.loadProfile()
    }
  },
  data () {
    return {
      user: null,
      loading: false,
      loaded: false
    }
  },
  methods: {
    loadProfile() {
      if (this.uid == null || this.uid == undefined || this.uid === '') {
        return
      } 
      this.loading = true
      let uri = API.baseURI+'user/profile/'+this.uid
      // console.log('uri',uri)
      axios.get(uri)
        .then(
          response => {             
            this.user = response.data.user            
            this.loading = false
            this.loaded = true            
          }
        )
        .catch(error => {
          //console.log(error)
          this.errors.push(error)
          this.loading = false
        })      
    }
  },
  mounted() {
    this.loadProfile()
  }
}
</script>