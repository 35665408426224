<template>
  <div class="charts-overview">
    <h1 class="page-title">ToSM - <span class="fw-semi-bold">Kompetisi</span></h1>
        
      <b-row>        
        <b-col md=4 sm=12 v-for="item in data" :key="item.id">            
          <b-spinner variant="warning" 
            type="grow" label="Spinning" v-if="loading">
          </b-spinner>                                              
          <Widget :title="item.judul" v-if="loaded">
            <h5>Penyelenggara: {{item.penyelenggara}}</h5>
            Mulai: {{item.start}} <br>
            Berakhir: {{item.end}} <br>
            <b-button class="m-1 small" variant="primary" @click="lihatKlasemen(item.query)">Lihat Klasemen</b-button>
          </Widget>
        </b-col>        
      </b-row>
          
  </div>
</template>

<script>
import Widget from '@/components/Widget/Widget';
import axios from '../../../node_modules/axios'
import API from '../../config/api'

export default {
  name: 'KompetisiOngoingPage',
  components: { Widget},
  data() {
    return {
      data: [],
      loading : true,
      loaded: false,
      errors: [],
    }
  },
  methods: {
    formatNumber(num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    },
    
    loadKompetisi() {
      this.loading = true
      axios.get(API.baseURI+'kompetisi/ongoing')
        .then(
          response => {            
            this.data = response.data.value
            this.loaded = true
            this.loading = false
          }
        )
        .catch(error => {
          this.errors.push(error)
          this.totalUser = 'Gagal mengambil data'
        })
    },
    
    lihatKlasemen(query) {
      this.$router.push('kompetisiklasemen?query='+query)
    }
  },
  mounted () {
    this.$eventHub.$on('refresh', this.loadData);
    this.loadKompetisi()
  },
  created () {
    //this.$eventHub.$on('refresh', this.loadData);
  },
};

</script>

<style src="./Overview.scss" lang="scss" scoped />
