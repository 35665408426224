<template>
  <div>
    <h1 class="page-title">ToSM - <span class="fw-semi-bold">Identitas mitra baru</span></h1>
    <div>
    <b-card class="mt-3" header="Lengkapi isian berikut">
    <b-form @submit="onSubmit" @reset="onReset" >
      <b-form-group
        id="input-group-1"
        label="Institusi"
        label-for="input-1"
        description="Masukkan nama resmi Institusi, misal: SMA Negeri 1 Atas Awan"
      >
        <b-form-input
          id="input-1"
          v-model="form.institusi"
          placeholder="Nama resmi institusi"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-2" label="Kata kunci" label-for="input-2"
        description="Kode institusi untuk digunakan siswa secara 
        seragam pada aplikasi ToSM, misal: SMKN 1 Atas Awan"
        >
        <b-form-input
          id="input-2"
          v-model="form.query"
          placeholder="Kode institusi"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-3" label="Kontak" label-for="input-3">
        <b-form-input
          id="input-3"
          v-model="form.kontak"
          placeholder="Nama kontak"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-4" label="Periode Program" label-for="input-3">
        <b-form-input v-model="start" type="date"  class="ml-2 mt-2"/>
            <span class="ml-2 mt-2">s.d</span>
        <b-form-input v-model="end" type="date"  class="ml-2 mt-2"/> 
      </b-form-group>

      

      <b-button type="submit" variant="primary" class="mr-3">Submit</b-button>
      <b-button type="reset" variant="danger">Reset</b-button>
    </b-form>
    </b-card>
    
  </div>
  </div>
</template>

<script>
import Widget from '@/components/Widget/Widget';

export default {
  name: 'ProgramCreate',
  components: { Widget},
  data() {
    return {
      form :{
        institusi: '',
        query: '',
        kontak: '',
        start: '',
        end: '',
      },
      start: '',
      end: '',
      errors: [],
    }
  },
  methods: {
    onSubmit(event) {
      event.preventDefault()
      if (this.form.institusi =='' 
        || this.form.query == '' || this.form.kontak == ''
        || this.start == '' || this.end == '') {
          return
        }
      this.form.start = this.start.replaceAll('-','')
      this.form.end = this.end.replaceAll('-','')
      this.form.deskripsi = 'Program pemetaan kemampuan hitung dasar intuitif'
      this.$http.post('program/create',this.form)
        .then(
          response => {
            let token = response.data.program.token
            // eslint-disable-next-line no-console
            //console.log('token:',response)                
            this.$router.push('/admin/datamitra/'+token)
          }
        )
        .catch(error => {          
          this.errors.push(error)          
        })
    },
    onReset(event) {
      event.preventDefault()      
      this.form.institusi = ''
      this.form.query = ''
      this.form.kontak = ''
      this.form.start = ''
      this.form.end = ''
      this.$nextTick(() => {
        
      })
    },
    setDefaultDate() {
      let date = new Date(), y = date.getFullYear(), m = date.getMonth()
      let startDt = new Date(y, m, 1);
      let endDt = new Date(y, m + 1, 0);
      this.start = ''+startDt.getFullYear()+'-'+('0'+(startDt.getMonth()+1)).slice(-2)+'-'+('0'+startDt.getDate()).slice(-2)
      // eslint-disable-next-line no-console
      //console.log('startp',this.startPeriod)
      this.end = ''+endDt.getFullYear()+'-'+('0'+(endDt.getMonth()+1)).slice(-2)+'-'+('0'+endDt.getDate()).slice(-2)
    }    
  },
  mounted () {
    this.$eventHub.$on('refresh', this.loadData);
    this.setDefaultDate()
    //this.loadData()
  },
  created () {
    //this.$eventHub.$on('refresh', this.loadData);
  },
};

</script>
