import { render, staticRenderFns } from "./KetuntasanA1User.vue?vue&type=template&id=62932904&scoped=true&"
import script from "./KetuntasanA1User.vue?vue&type=script&lang=js&"
export * from "./KetuntasanA1User.vue?vue&type=script&lang=js&"
import style0 from "./Ketuntasan.scss?vue&type=style&index=0&id=62932904&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62932904",
  null
  
)

export default component.exports