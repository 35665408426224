<template>
  <div class="login-page">
    <b-container>      
      <Widget class="mx-auto" title="<h3 class='mt-0'>Masuk ke dalam sistem</h3>" customHeader>        
        <p class="text-muted mb-0 mt ">
          Gunakan alamat email dan password anda untuk mengakses halaman terproteksi pada situs ini
        </p>
        
        <form class="mt" @submit.prevent="login">
          <b-alert class="alert-sm" variant="danger" :show="!!errorMessage">
            {{errorMessage}}
          </b-alert>
          <div class="form-group">
            <input class="form-control no-border" ref="email"
              required type="text" name="email" placeholder="Email" 
              />
          </div>
          <div class="form-group">
            <input class="form-control no-border" ref="password"
            required type="password" name="password" placeholder="Password" 
            />
          </div>
          <div class="clearfix">
            <div class="btn-toolbar float-right">
              
              <b-button type="submit" size="sm" variant="inverse">Login</b-button>
            </div>
          </div>
          
        </form>
      </Widget>
          <b-modal ref="fail-modal" hide-footer title="Login gagal">
            <div class="d-block text-center">
              <h3>Kombinasi email dan password anda salah</h3>
            </div>            
          </b-modal>
    </b-container>
    <footer class="footer">
      2021 &copy; Matematika Detik
    </footer>
  </div>
</template>

<script>
import Widget from '@/components/Widget/Widget';


export default {
  name: 'LoginPage',
  components: { Widget },
  data() {
    return {
      errors: null,
      errorMessage: null,
    };
  },
  methods: {
    login() {
      const email = this.$refs.email.value;
      const password = this.$refs.password.value;
      if (email.length !== 0 && password.length !== 0) {
        this.$http.post(
            'auth/login',{
            'email': email,
            'password': password 
          })
        .then(
          response => {            
            // eslint-disable-next-line no-console
            // console.log(response)    
            if (response.data.user) {
              this.$store.commit('setUser',response.data.user)
              this.$store.commit('setToken',response.data.access_token)
              this.$router.push('/admin/allmitra')
            } else {
              this.$refs['fail-modal'].show()
            }
            
          }
        )
        .catch(error => {          
          this.errors.push(error)          
        })
      }
    },
  },
  created() {
    if (window.localStorage.getItem('authenticated') === 'true') {
      //this.$router.push('/app/main/analytics');
    }
  },
};
</script>

<style src="./Login.scss" lang="scss" scoped />
