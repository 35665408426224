<template>
  <div>
    <h1 class="page-title">Profil - <span class="fw-semi-bold">Pengguna</span></h1>    
    <b-row>
      <b-col lg="6" class="my-1">
        <widget title="Identitas">
          <div >
            <info-user :uid="uid"/>
          </div>  
        </widget>
      </b-col>      
    </b-row>
    <b-row>
      <b-col lg="6" class="my-1">
        <widget title="Ketuntasan (A1)">
          <div >
            <ketuntasan-a1-user :uid="uid"/>
          </div>  
        </widget>
      </b-col>
      <b-col lg="6" class="my-1">
        <widget title="Statistik (A1)">
          <div >
            <stats-user :uid="uid"/>
          </div>  
        </widget>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="6" class="my-1">        
        <widget title="Grafik Riwayat Tes">
          <div >            
            <chart-historis-user :uid="uid"/>
          </div>  
        </widget>        
      </b-col>
      <b-col lg="6" class="my-1">        
        <widget title="Tabel Riwayat Tes">
          <div >            
            <table-historis-user :uid="uid"/>
          </div>  
        </widget>        
      </b-col>      
    </b-row>
  </div>
</template>

<script>
import Widget from '@/components/Widget/Widget';
import InfoUser from '../tosmcomponents/InfoUser'
import StatsUser from '../tosmcomponents/StatsUser'
import KetuntasanA1User from '../tosmcomponents/KetuntasanA1User'
import ChartHistorisUser from '../tosmcomponents/ChartHistorisUser'
import TableHistorisUser from '../tosmcomponents/TableHistorisUser'

export default {
  name: 'ProfileUserPage',
  components: { Widget,InfoUser,StatsUser,KetuntasanA1User,ChartHistorisUser,TableHistorisUser },
  data() {
    return {
      uid : '',
      errors: [],
    }
  },
  methods: {

  },
  mounted () {
    this.$eventHub.$on('refresh', this.loadData);
    this.uid = this.$route.params.uid    
  },
  created () {
    //this.$eventHub.$on('refresh', this.loadData);
  },
};

</script>

<style src="./Overview.scss" lang="scss" scoped />
<style scoped>
  .opmhitam {
    background: #000;
    color: white;    
  }
  .opmmerah {
    background: #F60000;
    color: white;
  }
  .opmkuning {
    background: #FFEE00;
    color: #444;
  }
  .opmhijau {
    background: #4DE94C;
    color: white;
  }
  .opmbiru {
    background: #3783FF;
    color: white;
    margin: auto;
  }
  .opmungu {
    background: #4815AA;
    color: white;
  }
  .opmputih1 {
    background: #fff;
    color: #000;
  }
  .opmputih2 {
    background: #fff;
    color: #000;
  }
</style>