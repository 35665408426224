<template>
  <div class="charts-overview">
    <h1 class="page-title">Test of Second Mathematics (ToSM) - <span class="fw-semi-bold"> Data & Statistik</span></h1>
    <br/>
    <div>
      <b-row>
        <b-col >          
            <b-card bg-variant="warning-light mb-2">
              <h5>Khusus mitra PPMD</h5>
              <b-form inline>
                <label class="mr-sm-2" for="inline-form-input-token">Masukkan token program</label>              
                <b-input v-model="token" @keyup.enter="toProgram"> </b-input>
                <b-button class="m-1 small" variant="warning" @click="toProgram">Submit</b-button>              
              </b-form>
              <span class="small italic mt-2">Mitra terdaftar dapat mengakses data sekolah/institusi masing-masing
                dengan menggunakan token. 
                Jika anda mewakili institusi dan tertarik dengan fitur ini, 
                hubungi kami melalui <a href="mailto:mathdetik@gmail.com">email</a> atau 
                <a href="https://wa.me/+6285742109009">whatsapp</a></span>
            </b-card>
          
        </b-col>
      </b-row>
      <b-row>
        <b-col  lg='12' sm='12'>
          <Widget>
            <h4>Subscribe channel Matematika Detik di Youtube</h4>
            <b-button class="small" href="https://www.youtube.com/channel/UCLSsGi3Aubi_Yez_WqwuWbg" target="_blank" variant="danger">SUBSCRIBE</b-button>
            <p></p>            
            <b-row>
              <b-col md='6' sm='12'>
                <div class="embed-responsive embed-responsive-16by9">
                  <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/SoFdamzNBdg?rel=0" allowfullscreen></iframe>
                </div>
                <p></p>
              </b-col>
              <b-col md='6' sm='12'>
                <div class="embed-responsive embed-responsive-16by9">
                  <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/ShaMdXqxwVQ?rel=0" allowfullscreen></iframe>
                </div>
              </b-col>
            </b-row>
          </Widget>
        </b-col>
      </b-row>
      <b-row>
        <b-col  lg='4' sm='12'>
          <Widget>
            <h4>ToSM & HINT</h4>
            <strong>ToSM (Test of Second Mathematics)</strong> merupakan instrumen baku dari Matematika Detik untuk melatih berhitung dasar secara spontan sekaligus identifikasi dan terapi gagap hitung.
            <br/>
            ToSM, bersama dengan HINT (Hitung Intuitif), merupakan bagian dari Matematika Detik Level A (Membaca Angka Secepat Membaca Kata). Informasi lebih jelas, dapat dibaca pada bukunya. <p/>
            
            <b-button v-b-toggle="'collapse-2'" class="m-1 small" variant="warning">Buku Matematika Detik</b-button>
            <p/>
            <b-collapse id="collapse-2">
            <b-card
              title="Buku Matematika Detik"              
              img-alt="Image"
              img-top
              tag="article"
              class="mb-2"
            > 
              <a href="/md.png" target="_blank">
                <b-img src="/md.png" fluid/>
              </a>
              <p/>
              <b-card-text>
                Pesan buku Matematika Detik, seri 1 dan 2, karya Ahmad Thoha Faz.<br/>
                Ubah cara pandang anda agar bisa memandu anak ataupun siswa anda menikmati Matematika
              </b-card-text>
              <b-button class="small" href="https://wa.me/6285786484830" target="_blank" variant="success">Pesan sekarang</b-button>
            </b-card>
            </b-collapse>
          </Widget>
          
        </b-col>
        <b-col lg='4' sm='12'>
          <Widget>
            <h4>ToSM on Android</h4>
            <strong>ToSM on Android</strong> adalah versi digital dari ToSM yang dapat dijalankan pada gawai (<i>smartphone</i>)
            berbasis android.<br/>
            Melalui ToSM on Android, data hasil tes kemudian kami himpun dan sajikan hasilnya melalui situs ini. <br/>
            Kami berharap dapat memetakan kecepatan berhitung dasar pengguna dari seluruh daerah di Indonesia
            <p/>
            <b-button v-b-toggle="'collapse-1'" class="m-1 small" variant="warning">ToSM on Android</b-button><p/>
            <b-collapse id="collapse-1">
            <b-card 
              title="ToSM on Android"
              img-src="../tosm3.png"              
              img-alt="Image"
              img-top
              tag="article"
              class="mb-2"
            >
              <b-card-text >
                Gunakan ToSM digital on android untuk identifikasi dan terapi gagap hitung siswa ataupun putra/putri anda
              </b-card-text>
              <b-button class="small" href="https://play.google.com/store/apps/details?id=com.matematikadetik.tosm" target="_blank" variant="primary">Download di playstore</b-button>
            </b-card>
            </b-collapse>
          </Widget>
        </b-col>
        <b-col lg='4' sm='12'>
          <Widget>
            <h4>Cara Kerja</h4>
            Tes dilakukan dengan menjawab sejumlah soal yang dihasilkan secara acak oleh aplikasi.<br/> 
            Hasil utama tes adalah nilai <strong>OPM</strong> (<i>Operations per Minute</i>) yang menunjukkan kecepatan berhitung subyek tes.<br/>
            Berdasarkan hasil tes ini subyek dapat didiagnosis menggunakan skala ToSM.
            <p/>    
            <b-button v-b-toggle="'collapse-3'" class="m-1 small" variant="warning">Skala ToSM A1</b-button><p/>
            <b-collapse id="collapse-3">
              <b-card                 
                class="mb-2"
              >
                <skala-a-1/>
              </b-card>
            </b-collapse>
          </Widget>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg='3' sm='12'>
          <Widget title="Hingga saat ini">
            <div class="d-flex justify-content-between align-items-center">              
              <h5>{{totalTest}}<small> kali</small></h5> Tes              
            </div>
            <div class="d-flex justify-content-between align-items-center">              
              <h5>{{totalUser}}<small> orang</small></h5> Pengguna
            </div>
            <geo-coverage/>
          </Widget>
          
        </b-col>        
        <b-col  xl=7 lg='9' sm='12'>
          <Widget title="Gagap Hitung Bilangan Asli">
            <h6>Persentase pengguna yang terdeteksi gagap hitung pada operasi yang melibatkan bilangan asli menggunakan instrumen ToSM Level A1</h6><br>
            <KetuntasanA1/>            
          </Widget>
        </b-col>        
                
      </b-row>      
      <b-row>
        <b-col lg="12"  sm="12" >
          <Widget title="Statistik OPM Level A1">
            <b-table striped responsive :items="summaryA1" :fields="summaryA1fields"></b-table>
          </Widget>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="12"  >
          <Widget title="25 Hasil Tes Terbaru">
            <b-table striped responsive :items="latestTests" :fields="latestTestsfields">              
            </b-table>
          </Widget>
        </b-col>
      </b-row>
    </div>
    
  </div>
</template>

<script>
import Widget from '@/components/Widget/Widget';
import YTChannel from '../../pages/tosmcomponents/YTChannel'
import SocialSharing from '../../../node_modules/vue-social-sharing'
import axios from '../../../node_modules/axios'
import API from '../../config/api'
import ChartTestCountByOperation from '../../pages/tosmcomponents/ChartTestCountByOperation'
import ChartTestCountByUser from '../../pages/tosmcomponents/ChartTestCountByUser'
import ChartTestCountOvertime from '../../pages/tosmcomponents/ChartTestCountOvertime'
import GeoCoverage from '../../pages/tosmcomponents/GeoCoverage'
import KetuntasanA1 from '../../pages/tosmcomponents/KetuntasanA1'
import SkalaA1 from './SkalaA1'


export default {
  name: 'OverviewPage',
  components: { Widget,ChartTestCountByOperation,ChartTestCountByUser,ChartTestCountOvertime,GeoCoverage,SocialSharing,SkalaA1,KetuntasanA1,YTChannel },
  metaInfo: {
      title: 'Overview',
      meta: [
       {property: 'og:image', content: 'logohi.png'}, 
      ]
    },
  data() {
    return {
      token : '',
      totalUser: 0,
      totalTest: 0,
      latestTests: [],
      latestTestsfields: [
          {
            key: 'alias', label: 'Pengguna'
          },          
          'provinsi','kabupaten',
          {
            key: 'org', label: 'Asal Sekolah'
          },
          {
            key: 'opm',
            formatter: value => {
              return value.toFixed(2)
            }
          },
          'operasi','level',
          {
            key: 'waktu_tes',
            formatter: value => {
              let d = new Date(value)
              let datestring = d.getDate()  + "-" + (d.getMonth()+1) + "-" + d.getFullYear() + " " +d.getHours() + ":" + d.getMinutes()
              return datestring
            }
          },  
        ],
      summaryA1: [],
      summaryA1fields: [
          'Operasi','Jumlah',
          {
            key: 'Rerata',
            formatter: value => {
              return value.toFixed(2)
            }
          },
          {
            key: 'Minimum',
            label: 'Terendah',
            formatter: value => {
              return value.toFixed(2)
            }
          },
          {
            key: 'Maksimum',
            label: 'Tertinggi',
            formatter: value => {
              return value.toFixed(2)
            }
          },  
          {
            key: 'StdDeviasi',
            label: 'Simp. Baku',
            formatter: value => {
              return value.toFixed(2)
            }
          },
        ],
      errors: [],
    }
  },
  methods: {
    formatNumber(num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    },
    loadData() {
      //console.log('load data')
      this.totalUser = 0
      this.totalTest = 0
      this.latestTests = []
      this.summaryA1 = []
      this.loadTotalUser()
      this.loadTotalTest()
      this.loadLatestTests()
      this.loadSummaryA1()
    },
    toProgram() {
      this.$router.push('program/'+this.token)
    },
    loadTotalUser() {
      axios.get(API.baseURI+'/stats/totaluser')
        .then(
          response => {
            this.totalUser = this.formatNumber(response.data.value[0])
          }
        )
        .catch(error => {
          this.errors.push(error)
          this.totalUser = 'Gagal mengambil data'
        })     
    },
    loadTotalTest() {
      axios.get(API.baseURI+'/stats/totaltesttaken')
        .then(
          response => {
            this.totalTest = this.formatNumber(response.data.value[0])
          }
        )
        .catch(error => {
          this.errors.push(error)
          this.totalTest = 'Gagal mengambil data'
        })          
    },
    loadLatestTests() {
      axios.get(API.baseURI+'/test/latest25')
        .then(
          response => {
            this.latestTests = response.data.value
          }
        )
        .catch(error => {
          this.errors.push(error)
          this.totalTest = 'Gagal mengambil data'
        })          
    },
    loadSummaryA1() {
      axios.get(API.baseURI+'/test/summary/A1')
        .then(
          response => {
            this.summaryA1 = response.data.value
          }
        )
        .catch(error => {
          this.errors.push(error)
          this.totalTest = 'Gagal mengambil data'
        })          
    },
  },
  mounted () {
    this.loadData()
    this.$eventHub.$on('refresh', this.loadData);  
  },
  created () {
    //this.$eventHub.$on('refresh', this.loadData);
  },
};

</script>

<style src="./Overview.scss" lang="scss" scoped />
