<template>
  <div>
    <h1 class="page-title">ToSM - <span class="fw-semi-bold">Detail Kompetisi</span></h1>
    <div>
      <b-row>        
        <b-col v-if="infoLoaded">
          <Widget title="">
            <h4>{{info.judul}}</h4>
            <h5>Diselenggarakan oleh {{info.penyelenggara}}</h5>
            Deskripsi: {{info.deskripsi}} <br>
            Tingkat/level: {{info.level}} <br>
            Cakupan: {{info.region}} <br>
            Mulai: {{info.start.toLocaleString()}} WIB<br>
            Berakhir: {{info.end.toLocaleString()}} WIB<br>
            <h4>&nbsp;</h4>
            <b-button class="btn-sm" variant="primary" @click="$router.go(-1)">Kembali</b-button>
          </Widget>
        </b-col>
      </b-row>
      <b-row>        
        <b-col md="12" class="my-1">
          <Widget title="Peserta terdaftar" v-if="infoLoaded">
            <ParticipantsKompetisi :k_id="k_id"/>
          </Widget>
        </b-col>
        <b-col md="6" class="my-1">
          <Widget title="Klasemen berdasarkan skor ToSM" v-if="infoLoaded">
            <KlasemenOPM :k_id="k_id" :uuid="info.uuid"/>
          </Widget>
        </b-col>
        <b-col md="6" class="my-1">
          <Widget title="Klasemen berdasarkan jumlah Tes" v-if="infoLoaded">
            <KlasemenTCount :k_id="k_id" :uuid="info.uuid"/>
          </Widget>
        </b-col>        
      </b-row>      
    </div>    
  </div>
</template>

<script>
import Widget from '@/components/Widget/Widget';
import ParticipantsKompetisi from '@/pages/tosmcomponents/ParticipantsKompetisi';
import KlasemenOPM from '@/pages/tosmcomponents/KlasemenOPM'
import KlasemenTCount from '@/pages/tosmcomponents/KlasemenTCount'

export default {
  name: 'KompetisiDetailAdmin',
  components: { Widget, ParticipantsKompetisi,KlasemenOPM,KlasemenTCount },
  data() {
    return {
      filter:'',
      k_id: '',
      resultsLoaded: false,
      infoLoaded: false,
      info: null,            
      errors: [],
    }
  },
  methods: {
    formatNumber(num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    },
    loadData() {
      this.filter = ''
      //this.infoLoaded = false
      //this.resultsLoaded = false      
      this.loadInfo()
      //this.loadResults()     
    },
    tmstToDate(tmst) {
      let date = new Date(tmst)
      date.setMinutes(date.getMinutes()-date.getTimezoneOffset())
      return date.toLocaleString()
    },
    loadInfo() {
      this.$http.get('kompetisi/detail/'+this.k_id)
        .then(
          response => {            
            this.info = response.data.value            
            this.infoLoaded = true
            // eslint-disable-next-line no-console
            //console.log("info",this.info)
          }
        )
        .catch(error => {
          this.errors.push(error)
          //this.totalUser = 'Gagal mengambil data'
        })
    },
  },
  mounted () {
    this.$eventHub.$on('refresh', this.loadData);
    this.k_id = this.$route.params.id    
    this.loadData()
  },
  created () {
    //this.$eventHub.$on('refresh', this.loadData);
  },
};

</script>
