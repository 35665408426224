<template>
  <div>          
      <b-row>
        <b-col lg="12"  sm="12" >          
          <b-form inline>
              <span class="mr-2">Saring &nbsp;</span>
              <b-input-group size="sm">
                <b-form-input
                  id="filter-input"
                  v-model="filter"
                  type="search"
                  placeholder="kata kunci penyaringan"
                ></b-form-input>

                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                </b-input-group-append>
              </b-input-group>
              <b-input-group-append class="ml-2">                  
                <b-button variant="success" @click="exportToExcel">Download (Excel)</b-button>
              </b-input-group-append>
              <b-input-group-append class="ml-2">                  
                <b-button variant="info" @click="openAPI">API</b-button>
              </b-input-group-append>
            </b-form>
            <b-table striped responsive :items="participants" :fields="fields" :filter="filter"
              :current-page="currentPage"
              :per-page="perPage"
              >
              <template #cell(index)="data">
                {{ data.index + 1 + ((currentPage-1)*perPage)}}
              </template>
              <template #cell(alias)="data">
                <router-link :to="'/app/kompetisi_standing/'+data.item.uid+'/'+uuid" target="_blank" > {{ data.item.alias }} </router-link>
              </template>
            </b-table>
            <br>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              size="sm"
              class="my-0"
            >
              <template #first-text><span class="text-primary">Awal</span></template>
              <template #prev-text><span class="text-primary">Sebelumnya</span></template>
              <template #next-text><span class="text-primary">Berikutnya</span></template>
              <template #last-text><span class="text-primary">Akhir</span></template>              
              <template #page="{ page, active }">
                <b v-if="active">{{ page }}</b>
                <i v-else>{{ page }}</i>
              </template>
            </b-pagination>          
        </b-col>
      </b-row>      
  </div>
</template>

<script>
import Widget from '@/components/Widget/Widget';
import JsonExcel from "vue-json-excel";
import XLSX from 'xlsx' 

export default {
  name: 'KlasemenTCount',
  components: { Widget,JsonExcel },
  props: ['k_id','uuid'],  
  data() {
    return {
      filter: '',
      excelData: [],      
      currentPage: 1,
      perPage: 25,
      totalRows: 0,
      participants: [],
      fields: [                
          {
            key: 'index',
            label: 'No.'
          },              
          {
            key: 'alias',
            label: 'Nama',
            sortable: true,            
          },       
          {
            key: 'org',
            label: 'Institusi',            
          },
          {
            key: 'tambahcount',
            label: '+',            
          },
          {
            key: 'kurangcount',
            label: '-',            
          },
          {
            key: 'kalicount',
            label: '×',            
          },
          {
            key: 'bagicount',
            label: '÷',            
          },
          {
            key: 'tcount',
            label: 'Total',            
          }        
        ],
      errors: [],
    }
  },
  methods: {
    formatNumber(num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    },
    loadData() {
      // eslint-disable-next-line no-console
      // console.log('load data')
      this.participants = []
      this.loadKlasemen()
    },
    prepareExcelData() {
      this.excelData = []
      for (let i=0;i<this.participants.length;i++) {
        let data = this.participants[i]
        let data_filtered = {
          peringkat: i+1,
          nama: data.alias,
          institusi: data.org,
          jum_tambah: data.tambahcount,
          jum_kurang: data.kurangcount,
          jum_kali: data.kalicount,
          jum_bagi: data.bagicount,
          jum_total: data.tcount
        }
        this.excelData.push(data_filtered)
      }
    },
    exportToExcel() { // On Click Excel download button
      let data_ws = XLSX.utils.json_to_sheet(this.excelData)       
      var wb = XLSX.utils.book_new() 
      XLSX.utils.book_append_sheet(wb, data_ws, 'Peringkat by Jumlah Tes') // sheetAName is name of Worksheet        
      XLSX.writeFile(wb, 'Peringkat_byjumtes.xlsx') // name of the file is 'book.xlsx'
    },
    loadKlasemen() {
      this.$http.get('kompetisi/klasementcount/'+this.k_id)
        .then(
          response => {
            // eslint-disable-next-line no-console
            // console.log("info",response.data.value[2])
            this.participants = response.data.value
            this.totalRows = this.participants.length
            this.prepareExcelData()
          }
        )
        .catch(error => {
          this.errors.push(error)
          // eslint-disable-next-line no-console
          //console.log("info",JSON.stringify(error))
          this.totalTest = 'Gagal mengambil data'
        })          
    },
    openAPI() {
      const url = 'https://alza.web.id/tosmapi/kompetisi/klasementcount/'+this.k_id
      window.open(url, '_blank', 'noreferrer');
    }
  },
  mounted () {
    // eslint-disable-next-line no-console
    //console.log('mounted')
    this.loadData()
    this.$eventHub.$on('refresh', this.loadData);  
  },
  created () {
    //this.$eventHub.$on('refresh', this.loadData);
  },
};

</script>

