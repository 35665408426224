<template>
  <div>
      <b-row>
        <b-col>Total: {{ participants.length }} peserta</b-col>
      </b-row>          
      <b-row>
        <b-col lg="12"  sm="12" class="mt-3">          
          <b-form inline>
              <span class="mr-2">Saring &nbsp;</span>
              <b-input-group size="sm">
                <b-form-input
                  id="filter-input"
                  v-model="filter"
                  type="search"
                  placeholder="kata kunci penyaringan"
                ></b-form-input>

                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                </b-input-group-append>

              </b-input-group>

            </b-form>
            <b-table striped responsive :items="participants" :fields="fields" :filter="filter"
              :current-page="currentPage"
              :per-page="perPage"
              >
                <template #cell(index)="data">
                  {{ data.index + 1 + ((currentPage-1)*perPage)}}
                </template>
                <template #cell(alias)="data">
                  <router-link :to="'/app/profile/'+data.item.uid" target="_blank" > {{ data.item.alias }} </router-link>
                </template>
            </b-table>
            <br>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              size="sm"
              class="my-0"
            >
              <template #first-text><span class="text-primary">Awal</span></template>
              <template #prev-text><span class="text-primary">Sebelumnya</span></template>
              <template #next-text><span class="text-primary">Berikutnya</span></template>
              <template #last-text><span class="text-primary">Akhir</span></template>              
              <template #page="{ page, active }">
                <b v-if="active">{{ page }}</b>
                <i v-else>{{ page }}</i>
              </template>
            </b-pagination>          
        </b-col>
      </b-row>      
  </div>
</template>

<script>
import Widget from '@/components/Widget/Widget';
export default {
  name: 'ParticipantsKompetisi',
  components: { Widget },
  props: ['k_id'],  
  data() {
    return {
      filter: '',      
      currentPage: 1,
      perPage: 25,
      totalRows: 0,
      participants: [],
      fields: [
          {
            key: 'index',
            label: 'No.'
          },                              
          {
            key: 'alias',
            label: 'Nama',
            sortable: true,            
          },          
          {
            key: 'prov',
            label: 'Provinsi',            
          },
          {
            key: 'kab',
            label: 'Kabupaten/Kota',            
          },
          {
            key: 'org',
            label: 'Institusi',            
          },
          {
            key: 'reg_time',
            label: 'Mendaftar',            
          },
          {
            key: 'verified',
            label: 'Terverifikasi',            
          }        
        ],
      errors: [],
    }
  },
  methods: {
    formatNumber(num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    },
    loadData() {
      // eslint-disable-next-line no-console
      // console.log('load data')
      this.participants = []
      this.loadParticipants()
    },
    
    loadParticipants() {
      this.$http.get('kompetisi/users/'+this.k_id)
        .then(
          response => {
            // eslint-disable-next-line no-console
            // console.log("info",response.data.value[0])
            this.participants = response.data.value
            this.totalRows = this.participants.length
            
          }
        )
        .catch(error => {
          this.errors.push(error)
          // eslint-disable-next-line no-console
          //console.log("info",JSON.stringify(error))
          this.totalTest = 'Gagal mengambil data'
        })          
    },
    openAPI() {
      const url = 'https://alza.web.id/tosmapi/kompetisi/users/'+this.k_id
      window.open(url, '_blank', 'noreferrer');
    }
  },
  mounted () {
    // eslint-disable-next-line no-console
    //console.log('mounted')
    this.loadData()
    this.$eventHub.$on('refresh', this.loadData);  
  },
  created () {
    //this.$eventHub.$on('refresh', this.loadData);
  },
};

</script>

