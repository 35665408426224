<template>
  <div>
        <h1>Privasi dan Kebijakan</h1>
        <h2> Umum </h2>
        <p>Kami, Matematika Detik, peduli terhadap data anda. Melalui kebijakan privasi ini kami menjelaskan bagaimana kami menghimpun, menggunakan, dan berbagi data anda.</p>
        <p>Dengan menggunakan aplikasi ToSM, berarti anda telah menyetujui segala persyaratan dari kebijakan ini. </p>
        <p>Kami sewaktu-waktu dapat melakukan perubahan terhadap kebijakan yang ada, perubahan tersebut akan ditampilkan pada halaman ini 7 hari sebelum kebijakan tersebut berlaku.</p>
        <h2> Informasi yang kami kumpulkan </h2>
      
        <p>Jika anda memutuskan untuk menggunakan aplikasi ini lebih dari 2 kali, kami akan meminta anda melengkapi data personal sebagai berikut:<br>        
        <ol>
          <li>* Username/Alias, anda dibebaskan untuk menggunakan alias sesuai keinginan anda</li>
          <li>* Daerah asal (Provinsi dan Kabupaten)</li>
          <li>* Tanggal lahir anda</li>
          <li>* Institusi/Sekolah asal</li>
        </ol>
        </p>
        <h2>Informasi hasil tes</h2>
        <p>Setiap tes yang anda lakukan dicatat oleh sistem, mencakup:
          <ol>
            <li> * Jenis tes </li>
            <li> * Skor yang anda dapatkan </li>
            <li> * Waktu pelaksanaan tes </li>
          </ol>
        </p>
        <h2> Penggunaan informasi </h2>
        Informasi yang kami kumpulkan akan kami gunakan keperluan berikut:
        <h5> Sebagai bahan riset internal </h5> 
        Sebagai bahan riset tim Pusat Pengembangan Matematika Detik (PPMD)
        <h5> Untuk ditampilkan pada website ToSM </h5>
        <ol>
          <li> * Untuk menampilkan daftar top skor pada jenis tes tertentu. </li>
          <li> * Untuk menampilkan daftar tes terbaru yang dilakukan oleh user. </li>
          <li> * Untuk agregasi data hasil tes berdasarkan kelompok jenis tes, asal daerah, dan pengelompokan lain yang dirasa perlu untuk ditampilkan pada website. </li>
        </ol>
        <h2> Perubahan data personal </h2>
        Anda memegang kendali penuh terhadap data personal anda. Kami hanya mencatat data terakhir yang anda berikan kepada sistem. 

        <h2> Pertanyaan / Kontak </h2>
        Jika anda memiliki pertanyaan terhadap kebijakan ini, anda dapat menghubungi kami melalui:<br>
        Email : math.detik@gmail.com<br>
        <br>
        Matematika Detik<br>
        Perumahan Griya MD (Mutiara Dika) Blok B Nomor 2, RT 01 RW 03, <br>
        Desa Kademangaran, Kecamatan Dukuhturi, Kabupaten Tegal <br>
        Jawa Tengah<br>
        <br>
        Revisi terakhir : Tegal, 31 Juli 2020

  </div>
</template>
<script>
export default {
  name: 'PrivPol',
  components: {},

}
</script>
<style>
  .ul {
    padding-left: 30;
  }
  .ol {
    padding-left: 30;
  }
</style>