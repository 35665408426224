import Vue from 'vue';
import Router from 'vue-router';

import Layout from '@/components/Layout/Layout';
import Login from '@/pages/Login/Login';
import ErrorPage from '@/pages/Error/Error';
// Core
import TypographyPage from '@/pages/Typography/Typography';

// Tables
import TablesBasicPage from '@/pages/Tables/Basic';

// Maps
import GoogleMapPage from '@/pages/Maps/Google';

// Main
import AnalyticsPage from '@/pages/Dashboard/Dashboard';

// Charts
//import ChartsPage from '@/pages/Charts/Charts';

// Ui
import IconsPage from '@/pages/Icons/Icons';
import NotificationsPage from '@/pages/Notifications/Notifications';

// Tosm
import OverviewPage from '@/pages/Tosm/Overview'
import RankPage from '@/pages/Tosm/Rank'
import RankUserPage from '@/pages/Tosm/RankUser'
import SebaranPage from '@/pages/Tosm/SebaranOPM'
import BadgeUserPage from '@/pages/Tosm/Badge'
import ProfileUserPage from '@/pages/Tosm/ProfileUserComplete'
import ProgramResult from '@/pages/Tosm/ProgramResult'
import KompetisiOngoingPage from '@/pages/Tosm/KompetisiOngoing'
import KompetisiKlasemen from '@/pages/Tosm/KompetisiKlasemen'
import UserDataDeleteRequest from '@/pages/Tosm/UserDeleteRequest'
import PrivPol from '@/pages/Tosm/Privpol'
import DaftarMitra from '@/pages_admin/AllMitra'
import DaftarKompetisi from '@/pages_admin/AllKompetisi'
import ProgramDetailAdmin from '@/pages_admin/ProgramDetailAdmin'
import KompetisiDetailAdmin from '@/pages_admin/KompetisiDetailAdmin'
import ProgramCreate from '@/pages_admin/ProgramCreate'
import KompetisiCreate from '@/pages_admin/KompetisiCreate'
import UserSearch from '@/pages_admin/UserSearch'
import RankKompetisiUser from '@/pages/Tosm/RankKompetisiUser'


Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'def',
      redirect: '/app/overview'
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
    },
    {
      path: '/error',
      name: 'Error',
      component: ErrorPage,
    },
    {
      path: '/badgea1/:uid',
      name: 'User Badge A1',
      component: BadgeUserPage
    },
    {
      path: '/admin',
      name: 'Admin',
      redirect: '/app/overview',
      component: Layout,
      children: [
        {
          path: 'allmitra',
          name: 'Daftar Mitra',
          component: DaftarMitra,
        },
        {
          path: 'usersearch',
          name: 'Pencarian user',
          component: UserSearch,
        },
        {
          path: 'datamitra/:token',
          name: 'Data Mitra - Admin',
          component: ProgramDetailAdmin,
        },
        {
          path: 'newmitra',
          name: 'Mitra Baru',
          component: ProgramCreate,
        },
        {
          path: 'newkompetisi',
          name: 'Kompetisi Baru',
          component: KompetisiCreate,
        },
        {
          path: 'allkompetisi',
          name: 'Daftar Kompetisi',
          component: DaftarKompetisi,
        },
        {
          path: 'kompetisi/:id',
          name: 'DetailKompetisi',
          component: KompetisiDetailAdmin,
        },
      ]
    },
    {
      path: '/app',
      name: 'Layout',
      redirect: '/app/overview',
      component: Layout,
      children: [
        {
          path: 'dashboard',
          name: 'AnalyticsPage',
          component: AnalyticsPage,
        },
        {
          path: 'typography',
          name: 'TypographyPage',
          component: TypographyPage,
        },
        {
          path: 'components/icons',
          name: 'IconsPage',
          component: IconsPage,
        },
        {
          path: 'notifications',
          name: 'NotificationsPage',
          component: NotificationsPage,
        },
        
        {
          path: 'tables',
          name: 'TablesBasicPage',
          component: TablesBasicPage,
        },
        {
          path: 'components/maps',
          name: 'GoogleMapPage',
          component: GoogleMapPage,
        },
        {
          path: 'overview',
          name: 'OverviewPage',
          component: OverviewPage,
        },
        {
          path: 'rank',
          name: 'RankPage',
          component: RankPage,
        },
        {
          path: 'rankuser/:uid',
          name: 'RankUserPage',
          component: RankUserPage,
        },
        {
          path: 'profile/:uid',
          name: 'ProfileUserPage',
          component: ProfileUserPage,
        },
        {
          path: 'sebaranOPM',
          name: 'SebaranPage',
          component: SebaranPage,
        },
        {
          path: 'program',
          name: 'program',
          component: ProgramResult,
        },
        {
          path: 'program/:token',
          name: 'program token',
          component: ProgramResult,
        },
        {
          path: 'kompetisi',
          name: 'kompetisi',
          component: KompetisiOngoingPage,
        },
        {
          path: 'kompetisiklasemen',
          name: 'klasemen kompetisi',
          component: KompetisiKlasemen,
        },
        {
          path: 'kompetisi_standing/:uid/:uuid',
          name: 'Peringkat kompetisi user',
          component: RankKompetisiUser,
        },
        {
          path: 'privacy-policy',
          name: 'PrivPol',
          component: PrivPol,
        },
        {
          path: 'permohonan-hapus-data',
          name: 'UserDataDeleteRequest',
          component: UserDataDeleteRequest,
        },
      ],
    },
  ],
});
