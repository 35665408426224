<template>
  <div class="charts-overview">
    <h1 class="page-title">ToSM - <span class="fw-semi-bold">Ringkasan hasil ToSM Anda</span></h1>    
    <div >
      <user-profile :uid="uid"/>
      <p v-if="validUID" class="fw-semi-bold">
        <router-link :to="'/badgea1/'+uid">
          <i class='fa fa-share-alt' /> &nbsp; Sebarkan hasil ini ke rekan-rekan anda
        </router-link>
      </p>      
      <b-row v-if="!validUID">
        <b-col lg='6' xl='6' xs='12' >
          <Widget title="Khusus pengguna aplikasi ToSM">
            Halaman ini hanya diperuntukkan bagi pengguna aplikasi <a target="_blank" href="https://play.google.com/store/apps/details?id=com.detikmatematika.tosm">ToSM digital</a>
            <br/>
            Anda dapat mengunjungi link di atas untuk memasang aplikasi ToSM digital pada perangkat android anda    
          </Widget>
        </b-col>
      </b-row>
      <b-row v-if="validUID">
        <b-col lg='6' xl='3' xs='12' >
          <Widget title="A1 TAMBAH" class="container">
            <div :class="kelasKetuntasan(dataTambah.opm,'text-center p-2 mb-2 align-middle ')" v-if="validUIDtambah">
              <h5 class="fw-semi-bold"  >{{ketuntasan(dataTambah.opm)}}</h5>
            </div>            
            <p v-if="validUIDtambah">  
              Rekor OPM
              <strong><h4>{{dataTambah.opm.toFixed(2)}}</h4></strong>              
              Peringkat <strong>ke-{{dataTambah.rank}}</strong> dari <strong>{{dataTambah.total}}</strong>
              <b-progress height={} class="progress-xs mt-2" :precision="1" variant="warning" :value="100-100*dataTambah.rank/dataTambah.total"></b-progress>
              Lebih baik dari <strong>{{(100-100*dataTambah.rank/dataTambah.total).toFixed(2)}}%</strong> pengguna                            
            </p>
            <p v-if="!validUIDtambah">  
              Anda belum memiliki catatan tes pada operasi TAMBAH
            </p>
          </Widget>
        </b-col>
        <b-col lg='6' xl='3' xs='12' >
          <Widget title="A1 KURANG">            
            <div :class="kelasKetuntasan(dataKurang.opm,'text-center p-2 mb-2 align-middle ')" v-if="validUIDkurang">
              <h5 class="fw-semi-bold"  >{{ketuntasan(dataKurang.opm)}}</h5>
            </div>
            <p v-if="validUIDkurang">  
              Rekor OPM
              <strong><h4>{{dataKurang.opm.toFixed(2)}}</h4></strong>              
              Peringkat <strong>ke-{{dataKurang.rank}}</strong> dari <strong>{{dataKurang.total}}</strong>
              <b-progress height={} class="progress-xs mt-2" :precision="1" variant="warning" :value="100-100*dataKurang.rank/dataKurang.total"></b-progress>
              Lebih baik dari <strong>{{(100-100*dataKurang.rank/dataKurang.total).toFixed(2)}}%</strong> pengguna                            
            </p>
            <p v-if="!validUIDkurang">  
              Anda belum memiliki catatan tes pada operasi KURANG
            </p>
          </Widget>

        </b-col>
        <b-col lg='6' xl='3' xs='12' >
          <Widget title="A1 KALI">
            <div :class="kelasKetuntasan(dataKali.opm,'text-center p-2 mb-2 align-middle ')" v-if="validUIDkali">
              <h5 class="fw-semi-bold" >{{ketuntasan(dataKali.opm)}}</h5>
            </div>
            <p v-if="validUIDkali">
              Rekor OPM
              <strong><h4>{{dataKali.opm.toFixed(2)}}</h4></strong>              
              Peringkat <strong>ke-{{dataKali.rank}}</strong> dari <strong>{{dataKali.total}}</strong>
              <b-progress height={} class="progress-xs mt-2" :precision="1" variant="warning" :value="100-100*dataKali.rank/dataKali.total"></b-progress>
              Lebih baik dari <strong>{{(100-100*dataKali.rank/dataKali.total).toFixed(2)}}%</strong> pengguna
            </p>
            <p v-if="!validUIDkali">  
              Anda belum memiliki catatan tes pada operasi KALI
            </p>
          </Widget>
        </b-col>
        <b-col lg='6' xl='3' xs='12' >
          <Widget title="A1 BAGI">
            <div :class="kelasKetuntasan(dataBagi.opm,'text-center p-2 mb-2 align-middle ')" v-if="validUIDbagi">
              <h5 class="fw-semi-bold" >{{ketuntasan(dataBagi.opm)}}</h5>
            </div>
            <p v-if="validUIDbagi">
              Rekor OPM
              <strong><h4>{{dataBagi.opm.toFixed(2)}}</h4></strong>              
              Peringkat <strong>ke-{{dataBagi.rank}}</strong> dari <strong>{{dataBagi.total}}</strong>
              <b-progress height={} class="progress-xs mt-2" :precision="1" variant="warning" :value="100-100*dataBagi.rank/dataBagi.total"></b-progress>
              Lebih baik dari <strong>{{(100-100*dataBagi.rank/dataBagi.total).toFixed(2)}}%</strong> pengguna
            </p>
            <p v-if="!validUIDbagi">  
              Anda belum memiliki catatan tes pada operasi BAGI
            </p>
          </Widget>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg='4' xs='12' >
          <Widget>
            <b-button v-b-toggle="'skala-a1'" class="m-1 small" variant="warning">Skala OPM A1</b-button><p/>
            <b-collapse id="skala-a1">
              <skala-a-1/>
            </b-collapse>
          </Widget>
        </b-col>
      </b-row>
    </div>
    
  </div>
</template>

<script>
import Widget from '@/components/Widget/Widget';
import axios from '../../../node_modules/axios'
import API from '../../config/api'
import SkalaA1 from './SkalaA1'
import UserProfile from './Profile'

export default {
  name: 'RankUserPage',
  components: { Widget,SkalaA1,UserProfile },
  data() {
    return {
      uid : '',
      validUID: false,
      validUIDtambah: false,
      validUIDkurang: false,
      validUIDkali: false,
      validUIDbagi: false,
      dataTambah : null,
      dataKurang : null,
      dataKali : null,
      dataBagi : null,
      topSkorA1Tambah: [],
      topSkorA1Kurang: [],
      topSkorA1Kali: [],
      topSkorA1Bagi: [],
      topSkorfields: [
          {
            key: 'index', label: '#'
          },
          {
            key: 'opm',
            formatter: value => {
              return value.toFixed(2)
            }
          },
          {
            key: 'alias', label: 'User',
            formatter: value => {
              if (value == null) return 'Anonim (N/A)'
              return value
            }
          },
          {
            key: 'prov', label: 'Provinsi'
          },
          {
            key: 'kab', label: 'Kabupaten'
          },
        ],
      errors: [],
    }
  },
  methods: {
    formatNumber(num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    },
    ketuntasan(opm) {
      if (opm>=70) {
        return "ISTIMEWA \u2605 \u2605"
      }
      else if (opm>=60) {
        return "ISTIMEWA \u2605"
      }
      else if (opm>=30) {
        return "TUNTAS"
      }
      return "GAGAP HITUNG"
    },
    kelasKetuntasan(opm, cl) {
      // let cl = 'text-center p-2 mb-2 align-middle '
      if (opm < 10 ) {
        cl += 'opmhitam'
      } 
      else if (opm < 20 ) {
        cl += 'opmmerah'
      } 
      else if (opm < 30 ) {
        cl += 'opmkuning'
      }
      else if (opm < 40 ) {
        cl += 'opmhijau'
      }
      else if (opm < 50 ) {
        cl += 'opmbiru'
      }
      else if (opm < 60 ) {
        cl += 'opmungu'
      }
      else {
        cl += 'opmputih'
      }
      return cl
    },
    loadData() {
      this.validUIDtambah = false
      this.validUIDkurang = false
      this.validUIDkali = false
      this.validUIDbagi = false
      if (this.uid ==='' || this.uid ==='0' || this.uid == 0) {
        return
      } else {
        this.loadRankA1()
      }
    },
    loadRankA1() {
      axios.get(API.baseURI+'test/urank/A1/tambah/'+this.uid)
        .then(
          response => {
            if ('rank' in response.data) {
              this.dataTambah = response.data
              this.validUID = true
              this.validUIDtambah = true
              this.$cookie.set('uid', this.uid, 1);
            }
            //console.log('TAMBAH',response.data)
          }
        )
        .catch(error => {
          this.errors.push(error)
          this.totalUser = 'Gagal mengambil data'
        })
      axios.get(API.baseURI+'test/urank/A1/kurang/'+this.uid)
        .then(
          response => {
            if ('rank' in response.data) {
              this.dataKurang = response.data
              this.validUID = true
              this.validUIDkurang = true
            }
            //console.log('KURANG',response.data)
          }
        )
        .catch(error => {
          this.errors.push(error)
          this.totalUser = 'Gagal mengambil data'
        })
      axios.get(API.baseURI+'test/urank/A1/kali/'+this.uid)
        .then(
          response => {
            if ('rank' in response.data) {
              this.dataKali = response.data
              this.validUID = true
              this.validUIDkali = true
            }
            //console.log('KALI',response.data)
          }
        )
        .catch(error => {
          this.errors.push(error)
          this.totalUser = 'Gagal mengambil data'
        })
      axios.get(API.baseURI+'test/urank/A1/bagi/'+this.uid)
        .then(
          response => {
            if ('rank' in response.data) {
              this.dataBagi = response.data
              this.validUID = true
              this.validUIDbagi = true
            }
            //console.log('BAGI',response.data)
          }
        )
        .catch(error => {
          this.errors.push(error)
          this.totalUser = 'Gagal mengambil data'
        })           
    },
  },
  mounted () {
    this.$eventHub.$on('refresh', this.loadData);
    if (this.$route.params.uid != 0 && this.$route.params.uid !=='' ) {
      //console.log('use param')
      this.uid = this.$route.params.uid
      this.$cookie.set('uid', this.uid, 1);
    } else {
      //console.log('use cookie')
      this.uid = this.$cookie.get('uid');
    }
    //console.log('uid on mounted:'+this.uid)
    this.loadData()
  },
  created () {
    //this.$eventHub.$on('refresh', this.loadData);
  },
};

</script>

<style src="./Overview.scss" lang="scss" scoped />
<style scoped>
  .opmhitam {
    background: #000;
    color: white;    
  }
  .opmmerah {
    background: #F60000;
    color: white;
  }
  .opmkuning {
    background: #FFEE00;
    color: #444;
  }
  .opmhijau {
    background: #4DE94C;
    color: white;
  }
  .opmbiru {
    background: #3783FF;
    color: white;
    margin: auto;
  }
  .opmungu {
    background: #4815AA;
    color: white;
  }
  .opmputih1 {
    background: #fff;
    color: #000;
  }
  .opmputih2 {
    background: #fff;
    color: #000;
  }
</style>