<template>
  <div>
    <h1 class="page-title">ToSM - <span class="fw-semi-bold">Pencarian User</span></h1>
    <div>
      <b-row>
        <b-col>
          <b-card class="mt-3" header="Form pencarian">
            <b-form @submit="onSubmit" @reset="onReset" >
              <b-form-group
                id="input-group-1"
                label="Nama user"
                label-for="input-1"                
              >
                <b-form-input
                  id="input-1"
                  v-model="form.phrase"
                  placeholder="Masukkan nama user yang akan dicari"                  
                ></b-form-input>
              </b-form-group>

              <b-form-group id="input-group-2" 
                label="Provinsi" 
                label-for="input-2"                
                >
                <b-form-input
                  id="input-2"
                  v-model="form.prov"
                  placeholder="Provinsi asal user"                  
                ></b-form-input>
              </b-form-group>

              <b-form-group id="input-group-3" 
                label="Kabupaten" label-for="input-3">
                <b-form-input
                  id="input-3"
                  v-model="form.kab"
                  placeholder="Kabupaten asal user"                  
                ></b-form-input>
              </b-form-group>

              <b-form-group id="input-group-3" 
                label="Institusi" label-for="input-3">
                <b-form-input
                  id="input-3"
                  v-model="form.org"
                  placeholder="Institusi/sekolah asal user"                  
                ></b-form-input>
              </b-form-group>

              <b-button type="submit" variant="primary" class="mr-3">Submit</b-button>
              <b-button type="reset" variant="danger">Reset</b-button>
            </b-form>
            </b-card>
        </b-col>
      </b-row>
      <b-row>
        
        <b-col lg="12" v-if="!dataLoaded" class="text-center">
          <b-spinner variant="warning" type="grow" label="Spinning"></b-spinner>
        </b-col>
        <b-col lg="12"  sm="12" v-if="dataLoaded">          
          <b-card class="mt-3" header="Hasil pencarian">          
          <b-table striped responsive :items="users" :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            >
            <template #cell(index)="data">
              {{ data.index + 1 + ((currentPage-1)*perPage)}}
            </template>
            <template #cell(alias)="data">              
              <router-link :to="'/app/profile/'+data.item.uid" target="_blank" > {{ data.item.alias }} </router-link>
            </template>
          </b-table>
          <br>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            size="sm"
            class="my-0"
          >
            <template #first-text><span class="text-primary">Awal</span></template>
            <template #prev-text><span class="text-primary">Sebelumnya</span></template>
            <template #next-text><span class="text-primary">Berikutnya</span></template>
            <template #last-text><span class="text-primary">Akhir</span></template>              
            <template #page="{ page, active }">
              <b v-if="active">{{ page }}</b>
              <i v-else>{{ page }}</i>
            </template>
          </b-pagination>                    
          </b-card>
        </b-col>        
      </b-row>      
    </div>    
  </div>
</template>

<script>
import Widget from '@/components/Widget/Widget';

export default {
  name: 'UserSearch',
  components: { Widget},
  data() {
    return {
      form : {
        phrase: '',
        prov: '',
        kab: '',
        org: ''
      },
      users: [],
      dataLoaded: true,      
      currentPage: 1,
      perPage: 25,
      totalRows: 0,
      mitras: [],
      fields: [          
          {
            key: 'index',
            label: 'No.'
          },
          {
            key: 'alias',
            label: 'Nama',                        
          },
          {
            key: 'prov',
            label: 'Provinsi'            
          },
          {
            key: 'kab',
            label: 'Kabupaten'            
          },
          {
            key: 'org',
            label: 'Institusi/Sekolah'            
          },                    
        ],                  
      errors: [],
    }
  },
  methods: {
    formatNumber(num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    },    
    onSubmit(event) {
      event.preventDefault()
      this.dataLoaded = false
      if (this.form.phrase =='' 
        && this.form.prov == '' && this.form.kab == ''
        && this.org == '' ) {
          return
        }      
      this.$http.post('user/search',this.form)
        .then(
          response => {
            this.dataLoaded = true
            this.users = response.data.value            
            this.totalRows = this.users.length
          }
        )
        .catch(error => {          
          this.errors.push(error)
          this.dataLoaded = true          
        })
    },
    onReset(event) {
      event.preventDefault()      
      this.form.phrase = ''
      this.form.prov = ''
      this.form.kab = ''
      this.form.org = ''      
      this.$nextTick(() => {
        
      })
    },
    
  },
  mounted () {
    this.$eventHub.$on('refresh', this.loadData);
    //this.loadData()
  },
  created () {
    //this.$eventHub.$on('refresh', this.loadData);
  },
};

</script>


