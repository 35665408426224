<template>
  <div>
    <h1 class="page-title">ToSM - <span class="fw-semi-bold">Hapus data anda</span></h1>
    <h3>Perhatian !</h3>
    <h4>
      Bila anda masih dapat mengakses data anda menggunakan aplikasi mobile, 
      anda dapat menghapus data anda dengan cara masuk ke halaman profil dan menekan tombol hapus data.
    </h4>
    <h4>
      Bila tidak, gunakan form di bawah ini untuk menghapus data. 
      Data yang anda masukkan ke form harus sama persis dengan data yang anda simpan
      saat masih menggunakan aplikasi.
    </h4>
    <p>
      &nbsp;
    </p>
    <div>
    <b-card class="mt-3" header="Lengkapi isian berikut">
    <b-form @submit="onSubmit" @reset="onReset" >
      <b-form-group
        id="input-group-1"
        label="Alias"
        label-for="input-1"
        description="Masukkan alias/nama anda pada aplikasi"
      >
        <b-form-input
          id="input-1"
          v-model="form.alias"
          placeholder="Alias/nama anda pada aplikasi"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-2" label="Provinsi" label-for="input-2"
        description="Nama provinsi sesuai yang anda masukkan pada aplikasi"
        >
        <b-form-input
          id="input-2"
          v-model="form.prov"
          placeholder="Nama Provinsi"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-3" label="Kabupaten" label-for="input-3"
        description="Nama kabupaten sesuai yang anda masukkan pada aplikasi"
        >
        <b-form-input
          id="input-3"
          v-model="form.kab"
          placeholder="Nama Kabupaten"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-4" label="Tanggal lahir" label-for="input-3"
      description="Tanggal lahir sesuai yang anda masukkan pada aplikasi"
        >
        <b-form-input v-model="dob" type="date"  class="ml-2 mt-2"/>
      </b-form-group>

      

      <b-button type="submit" variant="primary" class="mr-3">Submit</b-button>
      <b-button type="reset" variant="danger">Reset</b-button>
      <p>

      </p>
      <p>
        <i>
          Seluruh data harus sesuai dengan informasi anda pada aplikasi.
          Sistem tidak akan melakukan penghapusan jika terdapat ketidaksesuaian data.
        </i>
      </p>
    </b-form>
    </b-card>
    
  </div>
  </div>
</template>

<script>
import Widget from '@/components/Widget/Widget';

export default {
  name: 'UserDataDeleteRequest',
  components: { Widget},
  data() {
    return {
      form :{
        alias: '',
        prov: '',
        kab: '',
        dob: '',
      },
      dob: '',
      feedback: null,
      errors: [],
    }
  },
  methods: {
    onSubmit(event) {
      // eslint-disable-next-line no-console
      console.log('submitted ')
      event.preventDefault()
      if (this.form.alias =='' 
        || this.form.prov == '' || this.form.kab == ''
        || this.dob == '' ) {
          return
        }
      const dobseg = this.dob.split('-');
      this.form.dob = dobseg[2]+'/'+dobseg[1]+'/'+dobseg[0]
      this.form.alias = this.form.alias.toUpperCase()
      this.form.prov = this.form.prov.toUpperCase()
      this.form.kab = this.form.kab.toUpperCase()
      this.$http.post('userdelete/web',this.form)
        .then(
          response => {
            if (response.data.msg) {
              if (response.data.msg === 'User not found') {
                alert("Pengguna tidak ditemukan, perbaiki isian anda")
                // this.onReset(null)
              }
              if (response.data.msg === 'User info emptied') {
                alert("Terima kasih, data identitas anda telah dikosongkan")
                this.clearForm()
              }
            }
            // eslint-disable-next-line no-console
            console.log(response.data)
          }
        )
        .catch(error => {          
          this.errors.push(error)          
        })
    },
    onReset(event) {
      event.preventDefault()      
      this.clearForm()
      this.$nextTick(() => {
        
      })
    },
    clearForm() {
      this.form.alias = ''
      this.form.prov = ''
      this.form.kab = ''
      this.form.dob = ''
    },
    setDefaultDate() {
      let date = new Date(), y = date.getFullYear(), m = date.getMonth()
      let startDt = new Date(y, m, 1);
    
      this.dob = ''+startDt.getFullYear()+'-'+('0'+(startDt.getMonth()+1)).slice(-2)+'-'+('0'+startDt.getDate()).slice(-2)
     
    }    
  },
  mounted () {
    this.$eventHub.$on('refresh', this.loadData);
    this.setDefaultDate()
    //this.loadData()
  },
  created () {
    //this.$eventHub.$on('refresh', this.loadData);
  },
};

</script>
