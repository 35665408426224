<template>
  <div class="ketuntasan">         
      <b-row>
        <b-col lg="12" v-if="!dataLoaded" class="text-center">
          <b-spinner variant="warning" type="grow" label="Spinning"></b-spinner>
        </b-col>
        <b-col lg="12"  sm="12" v-if="dataLoaded">          
          <b-form inline>
            Pilih periode
            <b-form-input v-model="startPeriod" type="date"  class="ml-2 mt-2"/>
            <span class="ml-2 mt-2">s.d</span>
            <b-form-input v-model="endPeriod" type="date"  class="ml-2 mt-2"/> 
            <b-button variant="primary" class="ml-2 mt-2" @click="loadData">Muat ulang</b-button>
            <b-input-group-append class="ml-2 mt-2">                  
              <b-button variant="success" @click="exportToExcel">Download (Excel)</b-button>
            </b-input-group-append>
          </b-form>
          <b-form inline class=mt-3>
              Saring data yang tampil
              <b-input-group size="sm" class="ml-2">
                <b-form-input
                  id="filter-input"
                  v-model="filter"
                  type="search"
                  placeholder="kata kunci penyaringan"
                ></b-form-input>
                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''" class="mr-2">Clear</b-button>
                </b-input-group-append>
                
              </b-input-group>

            </b-form>
            <b-table striped responsive :items="participants" :fields="fields" :filter="filter"
              :current-page="currentPage"
              :per-page="perPage"
              >
              <template #cell(index)="data">
                {{ data.index + 1 + ((currentPage-1)*perPage)}}
              </template>
              <template #cell(opmtambah)="data">
                <span 
                  :class="kelasKetuntasan(data.item.opmtambah,'text-center p-2 mb-2 align-middle ')" >
                  
                  {{data.item.opmtambah>0?data.item.opmtambah.toFixed(2):"-"}}
                </span>
              </template>
              <template #cell(opmkurang)="data">
                <span 
                  :class="kelasKetuntasan(data.item.opmkurang,'text-center p-2 mb-2 align-middle ')" >
                  
                  {{data.item.opmkurang>0?data.item.opmkurang.toFixed(2):"-"}}
                </span>
              </template>
              <template #cell(opmkali)="data">
                <span 
                  :class="kelasKetuntasan(data.item.opmkali,'text-center p-2 mb-2 align-middle ')" >
                  
                  {{data.item.opmkali>0?data.item.opmkali.toFixed(2):"-"}}
                </span>
              </template>
              <template #cell(opmbagi)="data">
                <span 
                  :class="kelasKetuntasan(data.item.opmbagi,'text-center p-2 mb-2 align-middle ')" >
                  
                  {{data.item.opmbagi>0?data.item.opmbagi.toFixed(2):"-"}}
              </span>                
              </template>
              <template #cell(nama)="data">                
                <router-link :to="'/app/profile/'+data.item.uid" target="_blank" > {{ data.item.alias }} </router-link>
              </template>
              
            </b-table>
            <br>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="fill"
              size="sm"
              class="my-0"
            >
              <template #first-text><span class="text-primary">Awal</span></template>
              <template #prev-text><span class="text-primary">Sebelumnya</span></template>
              <template #next-text><span class="text-primary">Berikutnya</span></template>
              <template #last-text><span class="text-primary">Akhir</span></template>              
              <template #page="{ page, active }">
                <b v-if="active">{{ page }}</b>
                <i v-else>{{ page }}</i>
              </template>
            </b-pagination>          
        </b-col>
      </b-row>      
  </div>
</template>

<script>
import Widget from '@/components/Widget/Widget';
import axios from 'axios'
import API from '../../config/api'
import JsonExcel from "vue-json-excel";
import XLSX from 'xlsx' 

export default {
  name: 'MaxOpmProgramByPeriod',
  components: { Widget,JsonExcel },
  props: ['token'],  
  data() {
    return {
      filter: '',
      startPeriod: null,
      endPeriod: null,
      excelData: [],
      dataLoaded: false,      
      currentPage: 1,
      perPage: 25,
      totalRows: 0,
      participants: [],
      fields: [                              
          {
            key: 'index',
            label: 'No.',            
          },
          {
            key: 'nama',
            label: 'Nama',
            sortable: true,            
          },
          {
            key: 'suborg',
            label: 'KLS/GRP',
            sortable: true,            
          },
          {
            key: 'opmtambah',
            label: 'OPM +'                      
          },
          {
            key: 'opmkurang',
            label: 'OPM -'                      
          },
          {
            key: 'opmkali',
            label: 'OPM x'                      
          },          
          {
            key: 'opmbagi',
            label: 'OPM ÷'                      
          },
          
          {
            key: 'opmtotal',
            formatter: value => {
              if (value>0)
                return value.toFixed(2)
              return '-'
            }                      
          }        
        ],
      errors: [],
    }
  },
  methods: {
    formatNumber(num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    },
    loadData() {
      //console.log('load data')
      this.participants = []
      this.loadParticipants()
    },
    
    loadParticipants() {
      // eslint-disable-next-line no-console
      //console.log('start p',this.startPeriod)
      let dt1 = this.startPeriod.replaceAll('-','')
      let dt2 = this.endPeriod.replaceAll('-','')
      axios.get(API.baseURI+'program/data/maxopm/'+this.token+'/'+dt1+'/'+dt2)
        .then(
          response => {
            this.participants = response.data.value
            this.totalRows = this.participants.length
            this.prepareExcelData()
            this.dataLoaded = true
          }
        )
        .catch(error => {
          this.errors.push(error)
          this.totalTest = 'Gagal mengambil data'
          this.dataLoaded = true
        })          
    },
    prepareExcelData() {
      this.excelData = []
      for (let i=0;i<this.participants.length;i++) {
        let data = this.participants[i]
        let data_filtered = {
          nama: data.alias,
          kls_grp: data.suborg,
          opm_tambah: data.opmtambah?data.opmtambah.toFixed(2):'',
          opm_kurang: data.opmkurang?data.opmkurang.toFixed(2):'',
          opm_kali: data.opmkali?data.opmkali.toFixed(2):'',
          opm_bagi: data.opmbagi?data.opmbagi.toFixed(2):'',
          opm_total: data.opmtotal?data.opmtotal.toFixed(2):''
        }
        this.excelData.push(data_filtered)
      }
    },
    kelasKetuntasan(opm, cl) {
      // let cl = 'text-center p-2 mb-2 align-middle '
      if (opm < 10 ) {
        cl += 'opmhitam'
      } 
      else if (opm < 20 ) {
        cl += 'opmmerah'
      } 
      else if (opm < 30 ) {
        cl += 'opmkuning'
      }
      else if (opm < 40 ) {
        cl += 'opmhijau'
      }
      else if (opm < 50 ) {
        cl += 'opmbiru'
      }
      else if (opm < 60 ) {
        cl += 'opmungu'
      }
      else {
        cl += 'opmputih'
      }
      return cl
    },
    exportToExcel() { // On Click Excel download button
      let data_ws = XLSX.utils.json_to_sheet(this.excelData)       
      var wb = XLSX.utils.book_new() 
      XLSX.utils.book_append_sheet(wb, data_ws, 'Data OPM Siswa') // sheetAName is name of Worksheet        
      XLSX.writeFile(wb, 'ToSM_OPM_per_siswa.xlsx') // name of the file is 'book.xlsx'
    },
    setDefaultDate() {
      let date = new Date(), y = date.getFullYear(), m = date.getMonth()
      let startDt = new Date(y, m-6, 1);
      let endDt = new Date(y, m + 1, 0);
      this.startPeriod = ''+startDt.getFullYear()+'-'+('0'+(startDt.getMonth()+1)).slice(-2)+'-'+('0'+startDt.getDate()).slice(-2)
      // eslint-disable-next-line no-console
      //console.log('startp',this.startPeriod)
      this.endPeriod = ''+endDt.getFullYear()+'-'+('0'+(endDt.getMonth()+1)).slice(-2)+'-'+('0'+endDt.getDate()).slice(-2)
    }
  },

  mounted () {    
    this.setDefaultDate()
    this.loadData()
    this.$eventHub.$on('refresh', this.loadData);  
    
  },
  created () {
    //this.$eventHub.$on('refresh', this.loadData);
  },
};

</script>

<style src="./Ketuntasan.scss" lang="scss" scoped />