<template>
  <div>
    <h1 class="page-title">ToSM - <span class="fw-semi-bold">Daftar Mitra</span></h1>
    <div>
      <b-row>
        <b-col lg="12" v-if="!dataLoaded" class="text-center">
          <b-spinner variant="warning" type="grow" label="Spinning"></b-spinner>
        </b-col>
        <b-col lg="12"  sm="12" v-if="dataLoaded">          
          <b-form inline>
            <span class="mr-3">Saring/Cari &nbsp; &nbsp;</span>
            <b-input-group size="sm">
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="kata kunci penyaringan"
              ></b-form-input>
              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form>
          <b-table striped responsive :items="mitras" :fields="fields" :filter="filter"
            :current-page="currentPage"
            :per-page="perPage"
            >
            <template #cell(index)="data">
              {{ data.index + 1 + ((currentPage-1)*perPage)}}
            </template>
            <template #cell(nama)="data">              
              <router-link :to="'datamitra/'+data.item.token" > 
                {{ data.item.institusi }} 
              </router-link>
            </template>
          </b-table>
          <br>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            size="sm"
            class="my-0"
          >
            <template #first-text><span class="text-primary">Awal</span></template>
            <template #prev-text><span class="text-primary">Sebelumnya</span></template>
            <template #next-text><span class="text-primary">Berikutnya</span></template>
            <template #last-text><span class="text-primary">Akhir</span></template>              
            <template #page="{ page, active }">
              <b v-if="active">{{ page }}</b>
              <i v-else>{{ page }}</i>
            </template>
          </b-pagination>          
        </b-col>
      </b-row>      
    </div>    
  </div>
</template>

<script>
import Widget from '@/components/Widget/Widget';

export default {
  name: 'DaftarMitra',
  components: { Widget},
  data() {
    return {
      filter: '',
      startPeriod: null,
      endPeriod: null,
      excelData: [],
      dataLoaded: false,      
      currentPage: 1,
      perPage: 25,
      totalRows: 0,
      mitras: [],
      fields: [          
          {
            key: 'index',
            label: 'No.'
          },
          {
            key: 'nama',
            label: 'Institusi',                        
          },
          {
            key: 'query',            
          },          
          {
            key: 'deskripsi',            
          },
          {
            key: 'contact',
            label: 'Kontak',            
          },
          {
            key: 'token',
            label: 'Token',            
          },
          {
            key: 'start', 
            label: 'Mulai',           
          },
          {
            key: 'end', 
            label: 'Berakhir',           
          },
        ],                  
      errors: [],
    }
  },
  methods: {
    formatNumber(num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    },
    loadData() {
      this.filter = ''
      this.dataLoaded = false
      this.$http.get('program/all')
        .then(
          response => {
            this.mitras = response.data.value
            this.totalRows = this.mitras.length            
            this.dataLoaded = true
          }
        )
        .catch(error => {
          this.errors.push(error)
          //this.totalUser = 'Gagal mengambil data'
        })
    },
    
  },
  mounted () {
    this.$eventHub.$on('refresh', this.loadData);
    this.loadData()
  },
  created () {
    //this.$eventHub.$on('refresh', this.loadData);
  },
};

</script>


