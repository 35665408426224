<template>
  <div>    
    <div>
      <b-row>
        <b-col md=3 sm=12 v-for="item in operations" :key="item" class="text-center">            
            <b-spinner variant="warning" type="grow" label="Spinning" v-if="!dataLoaded[item]"></b-spinner>            
            OPERASI {{item}}          
            <BarChart 
              :chartdata="chartData[item]"
              :options="chartOptions"
              v-if="dataLoaded[item]"
              > 
            </BarChart>                      
        </b-col>
      </b-row>
    </div>    
  </div>
</template>

<script>
import axios from '../../../node_modules/axios'
import API from '../../config/api'
import BarChart from '../../components/charts/Bar'

export default {
  name: 'HistogramProgram',
  components: {BarChart },
  props: ['token'],
  data() {
    return {
      operations: ['TAMBAH','KURANG','KALI','BAGI'],
      dataLoaded: {},
      chartData: {},
      chartOptions : {
        layout: {
            padding: {
                left: 10,
                right: 10,
                top: 20,
                bottom: 10
            }
        },
        scales: {
          yAxes: [{
            ticks: {                
                min: 0,                
              },
            scaleLabel: {
              display: true,              
              labelString: 'JUMLAH SISWA'
            }
          }],
           xAxes: [{                        
            gridLines: {
              display: false
            },
            scaleLabel: {
              display: true,
              labelString: 'RENTANG SKOR'
            }
          }]
        },
        legend: {
            display: false
        },     
      },
      errors: []
    }
  },
  methods: {
    formatNumber(num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    },
    loadData(){
      for (let i=0;i<this.operations.length;i++) {
        this.loadByOperation(this.operations[i])
      }
    },
    loadByOperation(oper) {      
      axios.get(API.baseURI+'program/histogram/'+this.token+'/A1/'+oper)
        .then(
          response => { 
            let data = response.data.value
            this.chartData[oper] = this.constructChartData(data)
            this.$set(this.dataLoaded,oper,true)
          }
        )
        .catch(error => {
          //console.log(error)
          this.errors.push(error)          
        })      
      
    },
    constructChartData(data) {
      //console.log(data)
      let chartData = {
            labels: ['0-10','10-20','20-30','30-40','40-50','50-60','60-70','70+'],
            datasets: [{
              label: 'Jumlah Siswa',
              barPercentage: 1.0,
              categoryPercentage: 1.0,
              backgroundColor: [
                  'rgba(0, 0, 0, 0.8)',
                  'rgba(225, 50, 50, 0.8)',
                  'rgba(225, 225, 0, 0.8)',
                  'rgba(50, 255, 50, 0.8)',
                  'rgba(50, 50, 225, 0.8)',
                  'rgba(150, 0, 225, 0.8)',
                  'rgba(255, 255, 255, 0.8)',
                  'rgba(255, 255, 255, 0.8)'
                  
              ],
              borderColor: [
                  'rgba(252,201,96, 1)',
                  'rgba(252,201,96, 1)',
                  'rgba(252,201,96, 1)',
                  'rgba(252,201,96, 1)',
                  'rgba(252,201,96, 1)',
                  'rgba(252,201,96, 1)',
                  'rgba(252,201,96, 1)',
                  'rgba(252,201,96, 1)',
              ],
              borderWidth: 0.3
          }]
      }
      let chartval = []      
      for (let i=0;i<data.length;i++) {
        let d = data[i]
        //chartval[i] = d['jumlah']
        // eslint-disable-next-line no-console
        //console.log("kelas:"+d.kelas)
        switch (d.kelas) {
          case '0-10 HITAM':
            chartval[0] = d.jumlah
            break
          case '10-20 MERAH':
            chartval[1] = d.jumlah
            break
          case '20-30 KUNING':
            chartval[2] = d.jumlah
            break
          case '30-40 HIJAU':
            chartval[3] = d.jumlah
            break
          case '40-50 BIRU':
            chartval[4] = d.jumlah
            break
          case '50-60 UNGU':
            chartval[5] = d.jumlah
            break
          case '60-70 PUTIH *':
            chartval[6] = d.jumlah
            break
          case '70+ PUTIH **':
            chartval[7] = d.jumlah
        }
        
      }
      //console.log('vharval:'+chartval)
      chartData.datasets[0].data = chartval
      //console.log(chartData)
      return chartData
    }
  },
  mounted () {
    this.loadData()    
    this.$eventHub.$on('refresh', this.loadData);
  },
  created () {
    //this.$eventHub.$on('refresh', this.loadData);
  },
};

</script>

