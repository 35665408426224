<template>
  <div>
    <h1 class="page-title">ToSM - <span class="fw-semi-bold">Detail Data Mitra</span></h1>
    <div>
      <b-row>        
        <b-col v-if="infoLoaded">
          <Widget title="Informasi program">
            <h4>Institusi: {{info.query}}</h4>
            <h5>Deskripsi: {{info.deskripsi}}</h5>
            Kontak: {{info.contact}} <br>
            Token: {{info.token}} <br>
            Mulai: {{info.program_start}} <br>
            Berakhir: {{info.program_end}}<br>
            <h4>&nbsp;</h4>
            <p>
              <b-button variant="danger" @click="chgToken">Ganti Token</b-button>
            </p>
          </Widget>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="6" class="my-1">
          <Widget title="Statistik" v-if="infoLoaded">
            <stats-program :token="token"/>
          </Widget>
        </b-col>
        
        <b-col lg="6" class="my-1">
          <Widget title="Persentase Gagap Hitung" v-if="infoLoaded">
            <ketuntasan-a1-program :token="token"/>
          </Widget>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="my-1">
          <Widget title="Histogram Skor OPM" v-if="infoLoaded">
            <histogram-program :token="token"/>
          </Widget>
        </b-col>
      </b-row>      
      <b-row>        
        <b-col lg="12" class="my-1">
          <Widget title="Hasil Tes" v-if="infoLoaded">
            <max-opm-program-by-period :token="token"/>
          </Widget>
        </b-col>        
      </b-row>      
    </div>    
  </div>
</template>

<script>
import Widget from '@/components/Widget/Widget';
import axios from 'axios'
import API from '../config/api'
import StatsProgram from '../pages/tosmcomponents/StatsProgram.vue';
import KetuntasanA1Program from '../pages/tosmcomponents/KetuntasanA1Program'
import MaxOpmProgramByPeriod from '../pages/tosmcomponents/MaxOpmProgramByPeriod'
import HistogramProgram from '../pages/tosmcomponents/HistogramProgram'

export default {
  name: 'ProgramResultAdminPage',
  components: { Widget,StatsProgram,KetuntasanA1Program,MaxOpmProgramByPeriod,HistogramProgram},
  data() {
    return {
      filter:'',
      token: '',
      resultsLoaded: false,
      infoLoaded: false,
      info: null,            
      errors: [],
    }
  },
  methods: {
    formatNumber(num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    },
    loadData() {
      this.filter = ''
      this.infoLoaded = false
      this.resultsLoaded = false      
      this.loadInfo()
      //this.loadResults()     
    },
    
    loadResults() {
      axios.get(API.baseURI+'program/tests/'+this.token+'/25')
        .then(
          response => {
            this.results = response.data.value            
            this.resultsLoaded = true
          }
        )
        .catch(error => {
          this.errors.push(error)
          //this.totalUser = 'Gagal mengambil data'
        })
    },
    loadInfo() {
      axios.get(API.baseURI+'program/info/'+this.token)
        .then(
          response => {            
            this.info = response.data.value[0]            
            this.infoLoaded = true
          }
        )
        .catch(error => {
          this.errors.push(error)
          //this.totalUser = 'Gagal mengambil data'
        })
    },
    chgToken() {
      if(confirm("Ganti token mitra?")) {
          let data = {
          old_token: this.token
        }
        this.$http.post(API.baseURI+'program/chg_token',data)
        .then(
            response => {
              // eslint-disable-next-line no-console
              console.log('data:',response)
              //let token = response.data.program.token
              // eslint-disable-next-line no-console
              //console.log('token:',token)
              this.$router.push('/admin/allmitra')
            }
          )
          .catch(error => {          
            // eslint-disable-next-line no-console
            console.log('err:',error)
            this.errors.push(error)          
          })
      } else {
        return
      }
      
    }    
  },
  mounted () {
    this.$eventHub.$on('refresh', this.loadData);
    this.token = this.$route.params.token    
    this.loadData()
  },
  created () {
    //this.$eventHub.$on('refresh', this.loadData);
  },
};

</script>
